import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { PIIHashingField } from "src/components/destinations/pii-hashing-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useTiktokAdvertisersQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType, standardMappingSchema } from "src/utils/destinations";

import { TypeField } from "../type-field";

const TYPE_OPTIONS = [
  { label: "Web Events", value: "event" },
  { label: "Custom Audiences", value: "segment" },
];

export const validation = Yup.object().shape({
  type: Yup.string()
    .default("event")
    .oneOf(TYPE_OPTIONS.map((option) => option.value)),
  pixelCode: Yup.string().when("type", { is: "event", then: Yup.string().required() }),
  propertyMappings: COMMON_SCHEMAS.mappings,
  contextMappings: COMMON_SCHEMAS.mappings,
  timestampFrom: Yup.mixed().notRequired(),
  disablePIIHashing: Yup.boolean().notRequired(),

  advertiserId: Yup.string().when("type", { is: "segment", then: Yup.string().required() }),
  audienceName: Yup.string().notRequired(),
  calculateType: Yup.string()
    .oneOf(["IDFA_MD5", "GAID_MD5", "IDFA_SHA256", "GAID_SHA256", "EMAIL_SHA256", "PHONE_SHA256"])
    .notRequired(),
  mappings: Yup.array().when("type", {
    is: (v) => v === "segment",
    then: Yup.array().of(standardMappingSchema).required("Identifier column is required"),
    otherwise: Yup.array().notRequired().default([]),
  }),
});

const SEGMENT_MAPPINGS = {
  IDFA_MD5: "idfa",
  IDFA_SHA256: "idfa",
  GAID_MD5: "gaid",
  GAID_SHA256: "gaid",
  EMAIL_SHA256: "email",
  PHONE_SHA256: "phone",
};

const PROPERTY_OPTIONS = [
  { label: "Contents", value: "contents", type: StandardFieldType.ARRAY },
  { label: "Currency", value: "currency", type: StandardFieldType.ENUM },
  { label: "Value", value: "value", type: StandardFieldType.NUMBER },
  { label: "Description", value: "description", type: StandardFieldType.STRING },
  { label: "Query", value: "query", type: StandardFieldType.STRING },
  { label: "Event Name", value: "event", type: StandardFieldType.STRING },
  { label: "Timestamp", value: "timestamp", type: StandardFieldType.NUMBER },
];

const CONTEXT_OPTIONS = [
  { label: "Callback", value: "callback", type: StandardFieldType.STRING },
  { label: "Page Url", value: "url", type: StandardFieldType.STRING },
  { label: "Page Referrer", value: "referrer", type: StandardFieldType.STRING },
  { label: "User External ID", value: "external_id", type: StandardFieldType.STRING },
  { label: "Phone Number", value: "phone_number", type: StandardFieldType.STRING },
  { label: "Email", value: "email", type: StandardFieldType.STRING },
  { label: "IP Address", value: "ip", type: StandardFieldType.STRING },
  { label: "User Agent", value: "user_agent", type: StandardFieldType.STRING },
];

export const TiktokAdsForm: FC = () => {
  const { hightouchColumns, config, setConfig, model, errors, destination } = useDestinationForm();

  const {
    data: advertisersData,
    error: advertisersError,
    isFetching: loadingAdvertisers,
    refetch: listAdvertisers,
  } = useTiktokAdvertisersQuery(
    {
      destinationId: String(destination?.id),
    },
    { enabled: config?.type === "segment" },
  );

  const setIdentifierMapping = (selected) => {
    const type = config?.calculateType;
    if (type && selected?.value) {
      setConfig({ ...config, mappings: [{ type: "standard", to: SEGMENT_MAPPINGS[type], from: selected.value }] });
    } else {
      setConfig({ ...config, mappings: undefined });
    }
  };

  return (
    <>
      <TypeField options={TYPE_OPTIONS} />

      {config?.type === "event" && (
        <>
          <Section>
            <Grid gap={8}>
              <Field error={errors?.pixelCode} label={`Which TikTok Pixel code should we send data to?`} size="large">
                <Input
                  defaultValue={config?.pixelCode}
                  error={errors?.pixelCode}
                  placeholder={"Enter a Pixel code"}
                  sx={{ width: "240px" }}
                  onChange={(pixelCode) => setConfig({ ...config, pixelCode })}
                />
              </Field>
            </Grid>
          </Section>

          <Field label="Property mappings" size="large">
            <Section>
              <MappingsField options={PROPERTY_OPTIONS} property="propertyMappings" />
            </Section>
          </Field>

          <Section>
            <Field
              optional
              description="If this is empty, Hightouch will use the time the event arrives at the server. Mapping a timestamp in the field mappings will overwrite this field."
              error={errors?.timestampFrom}
              label="Which column contains the event timestamp?"
              size="large"
            >
              <Select
                isClearable
                isError={errors?.timestampFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
          </Section>
          <Section>
            <Field label="Context mappings" size="large">
              <MappingsField options={CONTEXT_OPTIONS} property="contextMappings" />
            </Field>
          </Section>
          <PIIHashingField />
        </>
      )}

      {config?.type === "segment" && (
        <>
          <Section>
            <Field
              description={"Select the advertiser you want to send data to."}
              error={advertisersError || errors?.advertiserId}
              label={"Which advertiser would you like to sync to?"}
              loading={loadingAdvertisers}
              reload={listAdvertisers}
              size="large"
            >
              <Select
                isError={advertisersError || errors?.advertiserId}
                isLoading={loadingAdvertisers}
                options={advertisersData?.tiktokListAdvertisers?.map((advertiser) => {
                  return { label: `${advertiser?.name} (${advertiser?.id})`, value: advertiser.id };
                })}
                placeholder="Select an advertiser..."
                value={config?.advertiserId}
                width="280px"
                onChange={(selected) => {
                  setConfig({ type: "segment", advertiserId: selected?.value });
                }}
              />
            </Field>
          </Section>

          <Field
            optional
            description="If this name is not specified, Hightouch defaults to
            using the model name to create the audience. NOTE: TikTok only
            allows one audience upload per day per audience."
            label="Would you like a custom name for the custom audience?"
            size="large"
          >
            <Input
              defaultValue={config?.audienceName}
              placeholder={model?.name}
              sx={{ width: "240px" }}
              onChange={(audienceName) => setConfig({ ...config, audienceName })}
            />
          </Field>

          <Field
            description="We create the audience using the encryption type selected. We also hash the identifiers (but you can disable this feature below)"
            label="What is the encryption type for your audience?"
            size="large"
          >
            <RadioGroup
              options={[
                {
                  label: "IDFA encrypted with SHA256",
                  value: "IDFA_SHA256",
                  description: "Apple IDFA (Identifier for Advertisers) encrypted with SHA256",
                },
                {
                  label: "IDFA encrypted with MD5",
                  value: "IDFA_MD5",
                  description: "Apple IDFA (Identifier for Advertisers) encrypted with MD5",
                },
                {
                  label: "GAID encrypted with SHA256",
                  value: "GAID_SHA256",
                  description: "GAID (Google Advertising ID) encrypted with SHA256",
                },
                {
                  label: "GAID encrypted with MD5",
                  value: "GAID_MD5",
                  description: "GAID (Google Advertising ID) encrypted with MD5",
                },
                {
                  label: "Email encrypted with SHA256",
                  value: "EMAIL_SHA256",
                  description: "Email encrypted with SHA256",
                },
                {
                  label: "Phone encrypted with SHA256",
                  value: "PHONE_SHA256",
                  description: "Phone encrypted with SHA256",
                },
              ]}
              value={config?.calculateType}
              onChange={(calculateType) => {
                setConfig({ ...config, calculateType });
              }}
            />
          </Field>

          {config.calculateType && (
            <>
              <Section>
                <Field
                  description={"Select the column that contains the user identifier."}
                  label={"Which column contains the identifier?"}
                  size="large"
                >
                  <Select
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    value={config.mappings?.find((m) => m.to === SEGMENT_MAPPINGS[config.calculateType])?.from || undefined}
                    width="280px"
                    onChange={setIdentifierMapping}
                  />
                </Field>
              </Section>
              <Section>
                <Field
                  description="Note: if you ignore rows with null identifiers, the sync may look like it succeeded when not all rows were synced."
                  label="Would you like Hightouch to ignore rows with null values for the identifier?"
                  size="large"
                >
                  <RadioGroup
                    options={[
                      { label: "No, fail the sync if any rows with null identifiers are encountered", value: undefined },
                      { label: "Yes, ignore rows with null identifiers", value: true },
                    ]}
                    value={config.ignoreNullIdentifiers}
                    onChange={(ignoreNullIdentifiers) => {
                      setConfig({ ...config, ignoreNullIdentifiers });
                    }}
                  />
                </Field>
              </Section>

              <PIIHashingField />
            </>
          )}
        </>
      )}
    </>
  );
};

export default {
  form: TiktokAdsForm,
  validation,
};
