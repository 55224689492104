import { useEffect, useState } from "react";

import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Text } from "theme-ui";

import { Settings } from "src/components/settings";
import { CreateNormalTunnelForm, CreateReverseTunnelForm } from "src/components/tunnels/create-tunnel";
import { DeleteTunnel } from "src/components/tunnels/delete-tunnel";
import { EditTunnelForm } from "src/components/tunnels/edit-tunnel";
import { useTestTunnelQuery, useTunnelsQuery } from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { CheckCircleIcon, XCircleIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Table } from "src/ui/table";

import { Modal } from "../../ui/modal";

enum TunnelType {
  REVERSE = "reverse",
  NORMAL = "normal",
}

export const Tunnels = () => {
  const navigate = useNavigate();
  const params = useParams<{ modal_type?: string }>();
  const modal = params?.modal_type;
  const [editTunnel, setEditTunnel] = useState();
  const [deleteTunnel, setDeleteTunnel] = useState<any>();
  const [tunnelError, setTunnelError] = useState<string | undefined | null>();

  const { data, refetch } = useTunnelsQuery();

  const startColumns = [
    {
      name: "Status",
      key: "tunnel",
      max: "30px",
      cell: ({ id }) => {
        const [polling, setPolling] = useState<boolean>(false);

        const { isFetching, data } = useTestTunnelQuery({ id }, { refetchInterval: polling ? 3000 : undefined });

        const error = data?.checkTunnel?.error;

        useEffect(() => {
          if (error) {
            setPolling(true);
          }
        }, [error]);

        return isFetching ? (
          <Spinner />
        ) : data?.checkTunnel?.success ? (
          <CheckCircleIcon color="green" />
        ) : (
          <XCircleIcon color="red" style={{ cursor: "pointer" }} onClick={() => setTunnelError(error)} />
        );
      },
    },
    {
      name: "Name",
      key: "tunnel.name",
    },
  ];

  const createdAtColumn = {
    name: "Created At",
    key: "tunnel",
    cell: ({ createdAt }) => moment(createdAt).calendar(),
  };

  const normalTunnelColumns = [
    ...startColumns,
    {
      name: "SSH Host",
      key: "tunnel",
      cell: ({ sshHost, sshPort }) => `${sshHost}:${sshPort}`,
    },
    {
      name: "Service Host",
      key: "tunnel",
      cell: ({ serviceHost, servicePort }) => `${serviceHost}:${servicePort}`,
    },
    createdAtColumn,
    {
      max: "100px",
      cell: ({ tunnel }) => {
        return (
          <Menu
            options={[
              {
                label: "Edit",
                onClick: () => setEditTunnel(tunnel),
              },
              {
                label: "Delete",
                onClick: () => setDeleteTunnel(tunnel),
                variant: "danger",
              },
            ]}
            strategy="fixed"
          />
        );
      },
    },
  ];

  const reverseTunnelColumns = [
    ...startColumns,
    {
      name: "Tunnel Port",
      key: "tunnel.tunnelPort",
    },
    createdAtColumn,
    {
      max: "24px",
      cell: ({ tunnel }) => {
        return (
          <Menu
            options={[
              {
                label: "Edit",
                onClick: () => setEditTunnel(tunnel),
              },
              {
                label: "Delete",
                onClick: () => setDeleteTunnel(tunnel),
                variant: "danger",
              },
            ]}
            strategy="fixed"
          />
        );
      },
    },
  ];

  const tunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.NORMAL);
  const reverseTunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.REVERSE);

  return (
    <>
      <Settings route="tunnels">
        <Grid gap={12}>
          <Column>
            <Row sx={{ justifyContent: "space-between", alignItems: "center", mb: 3 }}>
              <Heading variant="h3">Tunnels</Heading>
              <Button
                label="Add tunnel"
                onClick={() => {
                  navigate("/settings/tunnels/create");
                }}
              />
            </Row>
            <Table
              columns={normalTunnelColumns}
              data={tunnels}
              placeholder={{
                title: "No tunnels",
                error: "Tunnels failed to load, please try again.",
              }}
              sx={{ maxHeight: "600px" }}
            />
          </Column>

          <Column>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 3 }}>
              <Heading variant="h3">Reverse Tunnels</Heading>
              <Row sx={{ justifyContent: "flex-end" }}>
                <Button
                  label="Add reverse tunnel"
                  onClick={() => {
                    navigate("/settings/tunnels/create-reverse");
                  }}
                />
              </Row>
            </Row>

            <Table
              columns={reverseTunnelColumns}
              data={reverseTunnels}
              placeholder={{
                title: "No reverse tunnels",
                error: "Tunnels failed to load, please try again.",
              }}
              sx={{ maxHeight: "600px" }}
            />
          </Column>
        </Grid>
      </Settings>

      {modal === "create" && (
        <CreateNormalTunnelForm
          onClose={() => {
            navigate("/settings/tunnels");
          }}
        />
      )}
      {modal === "create-reverse" && <CreateReverseTunnelForm onClose={() => navigate("/settings/tunnels")} />}
      {deleteTunnel && (
        <DeleteTunnel
          id={deleteTunnel?.id}
          name={deleteTunnel?.name}
          refetch={() => refetch()}
          onClose={() => setDeleteTunnel(undefined)}
        />
      )}
      {editTunnel && <EditTunnelForm tunnel={editTunnel} onClose={() => setEditTunnel(undefined)} />}
      <Modal info isOpen={tunnelError ? true : false} title="Error" onClose={() => setTunnelError("")}>
        <Text sx={{ maxWidth: "800px" }}>{tunnelError}</Text>
      </Modal>
    </>
  );
};
