import { FC } from "react";

import { Text } from "theme-ui";

import { PageContainer } from "src/components/layout";
import { Logo } from "src/ui/brand";

export const Expired: FC = () => {
  return (
    <PageContainer sx={{ height: "100vh", justifyContent: "center" }}>
      <Logo color="secondary" size="200px" sx={{ mb: 16 }} />
      <Text sx={{ fontWeight: "bold", fontSize: 4, mb: 4 }}>Expired Invitation</Text>
      <Text sx={{ fontWeight: "bold", color: "base.6", textAlign: "center", maxWidth: "60ch" }}>
        Check that your account email is the same as the invite recipient or contact your workspace administrator
      </Text>
    </PageContainer>
  );
};
