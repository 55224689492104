import { FC } from "react";

import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { PartnerConnectionFragment, useRequestMembershipMutation } from "src/graphql";
import { Fade } from "src/ui/animations";
import { Box } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Link } from "src/ui/link";

export const Unauthorized: FC = () => {
  const { addToast } = useToasts();
  const { user, workspace } = useUser();
  const location = useLocation();
  const state = location?.state as { error: string; partnerConnection: PartnerConnectionFragment } | undefined;

  const { isLoading: requestLoading, mutateAsync: requestMembership, isSuccess } = useRequestMembershipMutation();

  return (
    <Card>
      <Fade sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
        <Box>
          <Lock />
        </Box>

        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 7,
            color: "secondary",
            my: 10,
            textAlign: "center",
          }}
        >
          Access restricted
        </Text>
        <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center", mb: 16, color: "base.7" }}>
          {state?.error || `You aren’t authorized to access this resource`}
        </Text>

        {state?.partnerConnection && (
          <Button
            disabled={isSuccess}
            loading={requestLoading}
            size="large"
            sx={{ width: "100%", mb: 4 }}
            onClick={async () => {
              await requestMembership({
                workspaceId: String(state.partnerConnection.workspaceId),
                partnerConnectionId: state.partnerConnection.uuid,
              });
              addToast(`Access requested`, { appearance: "success" });
            }}
          >
            Request access
          </Button>
        )}
        {workspace ? (
          <Link sx={{ width: "100%" }} to="/">
            <Button propagate size="large" sx={{ width: "100%" }} variant="secondary">
              Back to workspace
            </Button>
          </Link>
        ) : user ? (
          <Link slug={false} sx={{ width: "100%" }} to="/workspaces">
            <Button propagate size="large" sx={{ width: "100%" }} variant="secondary">
              Back to workspaces
            </Button>
          </Link>
        ) : (
          <>
            <Link slug={false} sx={{ mb: 4, width: "100%" }} to="/login">
              <Button propagate size="large" sx={{ width: "100%" }} variant="secondary">
                Login
              </Button>
            </Link>
            <Link slug={false} sx={{ width: "100%" }} to="/signup">
              <Button propagate size="large" sx={{ width: "100%" }} variant="secondary">
                Signup
              </Button>
            </Link>
          </>
        )}
      </Fade>
    </Card>
  );
};

const Lock: FC = () => (
  <svg fill="none" viewBox="0 0 24 24" width="56" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.7688 8.71384H16.2312C18.5886 8.71384 20.5 10.583 20.5 12.8885V17.8253C20.5 20.1308 18.5886 22 16.2312 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8253V12.8885C3.5 10.583 5.41136 8.71384 7.7688 8.71384ZM11.9954 17.3295C12.4933 17.3295 12.8896 16.9419 12.8896 16.455V14.2489C12.8896 13.7719 12.4933 13.3844 11.9954 13.3844C11.5076 13.3844 11.1113 13.7719 11.1113 14.2489V16.455C11.1113 16.9419 11.5076 17.3295 11.9954 17.3295Z"
      fill="#1F0D69"
      fillRule="evenodd"
    />
    <path
      d="M17.523 7.39595V8.86667C17.1673 8.7673 16.7913 8.71761 16.4052 8.71761H15.7447V7.39595C15.7447 5.37868 14.0681 3.73903 12.0053 3.73903C9.94257 3.73903 8.26594 5.36874 8.25578 7.37608V8.71761H7.60545C7.20916 8.71761 6.83319 8.7673 6.47754 8.87661V7.39595C6.4877 4.41476 8.95692 2 11.985 2C15.0537 2 17.523 4.41476 17.523 7.39595"
      fill="#1F0D69"
      opacity=".4"
    />
  </svg>
);
