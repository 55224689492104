import { useEffect, FC } from "react";

import { Route, Routes, Navigate, useLocation, useParams } from "react-router-dom";

import { Layout } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import NotFoundPage from "src/pages/not-found";

import { ErrorBoundary } from "../error-boundary";
import { config, RouteObject } from "./config";

const CustomRoute: FC<Readonly<RouteObject>> = ({
  analyticsData = { pageName: "" },
  component,
  unauthorizedRedirect,
  permissions,
}) => {
  const { user, hasPermissions } = useUser();
  const { pathname } = useLocation();
  const params = useParams();

  useEffect(() => {
    // send data to analytics when route renders
    if (analyticsData?.pageName && user) {
      const { pageName, ...data } = analyticsData;
      const { id, ...otherParams } = params;

      const dataToSend = {
        ...data,
        ...otherParams,
      };

      // 'id' is not an allowed key for analytics
      if (id) {
        dataToSend["resource_id"] = id;
      }

      analytics.track(`${pageName} Viewed`, dataToSend);
    }
  }, [analyticsData, pathname, params, user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (permissions && !hasPermissions(permissions) && unauthorizedRedirect) {
    let unauthorizedRedirectWithParams = unauthorizedRedirect;

    // If user enters unauthorized route:
    // bounce user back to page that makes the most sense.
    // Ex: syncs/123/debugger/604 -> syncs/123/debugger
    for (const [key, value] of Object.entries(params)) {
      unauthorizedRedirectWithParams = unauthorizedRedirectWithParams.replace(`:${key}`, value || "");
    }

    return <Navigate to={unauthorizedRedirectWithParams} />;
  }

  return <>{component}</>;
};

export const RootRedirect: FC = () => {
  const { onboarding } = useUser();

  return <Navigate to={onboarding ? "onboarding" : "syncs"} />;
};

const routes = config.map((props) => (
  <Route
    key={props.path}
    element={
      <ErrorBoundary>
        <CustomRoute {...props} />
      </ErrorBoundary>
    }
    path={props.path}
  />
));

export const WorkspaceRouter: FC = () => {
  const { slug } = useUser();

  if (!slug) {
    return null;
  }

  return (
    <Routes>
      <Route element={<Layout />} path={`/${encodeURIComponent(slug)}`}>
        {routes}
        <Route index element={<RootRedirect />} />
        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Routes>
  );
};
