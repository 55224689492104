import { useEffect, FC } from "react";

import { TimeType } from "@hightouch/lib/query/visual/types";
import moment from "moment";
import { Text } from "theme-ui";

import {
  AbsoluteRelativeTimestampOperators,
  DateIntervalOptions,
  IntervalOperators,
  IntervalOptions,
  IntervalUnit,
  IntervalValue,
  OperatorsWithoutValue,
  PropertyCondition,
  TimestampOperator,
  Window,
} from "src/types/visual";
import { DateTimeSelect } from "src/ui/datetime-select";
import { Input } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";

// eslint-disable-next-line import/no-absolute-path

type Props = {
  condition: PropertyCondition | Window;
  onChange: (updates: Partial<PropertyCondition | Window>) => void;
  hideTime: boolean;
};

export const TimestampInput: FC<Readonly<Props>> = ({ condition, onChange, hideTime }) => {
  useEffect(() => {
    if (!condition.timeType) {
      // Default the timeType to "relative".
      onChange({ timeType: TimeType.Relative });
    }
  }, [condition?.timeType]);

  if (AbsoluteRelativeTimestampOperators.includes(condition.operator)) {
    return <AbsoluteRelativeTimeInput condition={condition} hideTime={hideTime} onChange={onChange} />;
  }

  return (
    <>
      {IntervalOperators.includes(condition.operator) ? (
        <TimeIntervalInput
          hideTime={hideTime}
          setValue={(value) => {
            onChange({ value });
          }}
          value={condition.value}
        />
      ) : (
        !OperatorsWithoutValue.includes(condition.operator) && (
          <TimestampComparisonInput
            hideTime={hideTime}
            setValue={(value) => {
              onChange({ value });
            }}
            value={condition.value}
          />
        )
      )}
    </>
  );
};

const AbsoluteRelativeTimeInput = ({ condition, onChange, hideTime }: Props) => {
  return (
    <>
      <NewSelect
        options={[
          { label: "relative", value: TimeType.Relative },
          { label: "absolute", value: TimeType.Absolute },
        ]}
        sx={{ flex: "0 0 auto" }}
        value={condition.timeType}
        onChange={(timeType: TimeType) => {
          onChange({ value: null, timeType });
        }}
      />

      {condition.operator === TimestampOperator.Between ? (
        <TimeRangeInput
          afterValue={condition.value?.after}
          beforeValue={condition.value?.before}
          hideTime={hideTime}
          setAfterValue={(value) => {
            onChange({ value: { ...condition.value, after: value } });
          }}
          setBeforeValue={(value) => {
            onChange({ value: { ...condition.value, before: value } });
          }}
          timeType={condition.timeType ?? TimeType.Relative}
        />
      ) : condition.timeType === TimeType.Absolute ? (
        <TimestampComparisonInput
          hideTime={hideTime}
          setValue={(value) => {
            onChange({ value });
          }}
          value={condition.value}
        />
      ) : (
        <TimeIntervalInput
          hideTime={hideTime}
          setValue={(value) => {
            onChange({ value });
          }}
          value={condition.value}
        />
      )}
    </>
  );
};

const TimeRangeInput: FC<
  Readonly<
    | {
        timeType: TimeType.Absolute;
        beforeValue: string;
        afterValue: string;
        setAfterValue: (value: string) => void;
        setBeforeValue: (value: string) => void;
        hideTime: boolean;
      }
    | {
        timeType: TimeType.Relative;
        beforeValue: IntervalValue;
        afterValue: IntervalValue;
        setAfterValue: (value: IntervalValue) => void;
        setBeforeValue: (value: IntervalValue) => void;
        hideTime: boolean;
      }
  >
> = (props) => {
  if (props.timeType === TimeType.Absolute) {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } = props;
    return (
      <>
        <TimestampComparisonInput hideTime={hideTime} setValue={setBeforeValue} value={beforeValue} />
        <Text sx={{ flexShrink: 0 }}>and</Text>
        <TimestampComparisonInput hideTime={hideTime} isRangeEnd={true} setValue={setAfterValue} value={afterValue} />
      </>
    );
  } else {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } = props;
    return (
      <>
        <TimeIntervalInput hideTime={hideTime} setValue={setBeforeValue} value={beforeValue} />
        <Text sx={{ flexShrink: 0 }}>and</Text>
        <TimeIntervalInput hideTime={hideTime} isRangeEnd={true} setValue={setAfterValue} value={afterValue} />
      </>
    );
  }
};

const TimeIntervalInput = ({
  value,
  setValue,
  isRangeEnd = false,
  hideTime = false,
}: {
  value: IntervalValue;
  setValue: (value: IntervalValue) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
}) => {
  // Handles default values.
  if (!value?.quantity && !value?.interval) {
    if (isRangeEnd) {
      setValue({ quantity: 2, interval: IntervalUnit.Month });
    } else {
      setValue({ quantity: 1, interval: IntervalUnit.Month });
    }

    return null;
  }

  return (
    <>
      <Input
        placeholder="time..."
        sx={{ width: "70px", flex: "0 0 auto" }}
        value={value?.quantity ? String(value.quantity) : null}
        onChange={(v) => setValue({ ...value, quantity: Number(v) })}
      />
      <NewSelect
        options={hideTime ? DateIntervalOptions : IntervalOptions}
        placeholder={"unit..."}
        sx={{ flex: "0 0 auto" }}
        value={value?.interval}
        onChange={(interval) => setValue({ ...value, interval })}
      />
    </>
  );
};

const TimestampComparisonInput = ({
  value,
  setValue,
  isRangeEnd = false,
  hideTime = false,
}: {
  value: string;
  setValue: (value: string) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
}) => {
  const formatValue = (value) => moment(value).utc(true).format("YYYY-MM-DD HH:mm:ss");

  // Handles default values.
  if (!value) {
    if (isRangeEnd) {
      const now = new Date();

      const oneMonthAgo = new Date(now);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      // Handles end of the month edge cases when the amount of days
      // in consecutive months are not aligned.
      while (now.getMonth() === oneMonthAgo.getMonth()) {
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);
      }

      setValue(formatValue(oneMonthAgo));
    } else {
      setValue(formatValue(new Date()));
    }

    return null;
  }

  return (
    <DateTimeSelect
      hideTime={hideTime}
      value={new Date(value)}
      onChange={(value) => {
        setValue(formatValue(value));
      }}
    />
  );
};
