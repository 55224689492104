import { useEffect, useMemo, FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications2";
import { Box } from "theme-ui";

import { TunnelSelect } from "src/components/tunnels/tunnel-select";
import { Form as FormkitForm } from "src/formkit/components/form";
import { FormkitProvider } from "src/formkit/components/formkit-context";
import { processFormNode } from "src/formkit/formkit";
import { SourceDefinition, useFormkitSourceDefinitionQuery, useFormkitSourceValidationQuery } from "src/graphql";
import { Row } from "src/ui/box/row";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { RadioGroup } from "src/ui/radio";

import { colors } from "../../../../../design";
import { IPWhitelistMessage } from "../ip-whitelist-message";
import { CredentialSection } from "./providers";

export type FormProps = {
  sourceId: string | undefined;
  definition: SourceDefinition;
  tunnel?: any;
  source?: any;
  setTunnel?: any;
  config?: any;
  setConfig?: any;
  credentialId?: any;
  setCredentialId?: any;
  name?: string;
  setName?: any;
  hideSecret?: boolean;
  error?: any;
  onSubmit?: (config: any) => Promise<void>;
  /**Wheter to disable authentication method radio selection. */
  disableAuthMethod?: boolean;
  /**Function called whenever OAuth 'connect' button is clicked. */
  onConnectClick?(defintion: SourceDefinition): void;
  isSetup: boolean;
};

export const SourceForm: FC<Readonly<FormProps>> = ({ definition, setConfig, config, sourceId, ...props }) => {
  const client = useQueryClient();
  const { addToast } = useToasts();
  const { data } = useFormkitSourceDefinitionQuery({ type: definition?.type }, { enabled: true });

  const sourceDefinitions = data?.formkitSourceDefinition;
  const context = {
    sourceDefinition: definition,
    sourceId,
    isSetup: props.isSetup,
    tunnel: props.tunnel,
    credentialId: props.credentialId,
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSourceValidationQuery.fetcher({
        type: context.sourceDefinition?.type,
        config,
        context,
      }),
      queryKey: useFormkitSourceValidationQuery.getKey({ ...config, ...context }),
    });

    return response.formkitSourceValidation;
  };

  const methods = useForm();

  useEffect(() => {
    if (config && Object.keys(config).length) {
      methods.reset(config, { keepDefaultValues: true });
    }
  }, [typeof config]);

  useEffect(() => {
    //Autoset authentication method if only one
    const firstSourceDefinition = sourceDefinitions?.[0];

    if (sourceDefinitions?.length === 1 && firstSourceDefinition) {
      setConfig((prev) => ({ ...prev, methodKey: firstSourceDefinition.key }));
      methods.setValue("methodKey", firstSourceDefinition.key);
    }
  }, [definition.type, sourceDefinitions?.length]);

  useEffect(() => {
    //Watches fields and sets config
    const fieldWatcher = methods.watch((value) => {
      setConfig(value);
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sourceDefinitions?.length === 0) {
      if (typeof props.onSubmit === "function") {
        return await props.onSubmit(data);
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });
      addToast("There was an error within the source configuration.", {
        appearance: "error",
      });
    } else {
      if (typeof props.onSubmit === "function") {
        await props.onSubmit(data);
      }
    }
  };

  if (!sourceDefinitions || !Array.isArray(sourceDefinitions)) return null;
  return (
    <FormkitProvider {...context} validate={validate}>
      <FormProvider {...methods}>
        {definition.supportsIpFiltering && (
          <Row>
            <IPWhitelistMessage />
          </Row>
        )}
        {sourceDefinitions.length > 1 && (
          <Field label="Authentication Method">
            <Controller
              control={methods.control}
              name={`methodKey`}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  disabled={props.disableAuthMethod}
                  options={sourceDefinitions.map((o) => ({ label: o.label || capitalize(o.key), value: o.key }))}
                  value={config?.methodKey}
                />
              )}
              rules={{ required: true }}
            />
          </Field>
        )}
        <SuccessMessage display={Boolean(props.disableAuthMethod && sourceId && props.isSetup)} sourceName={definition.name} />
        <SelectedMethodForm
          config={config}
          credentialId={props.credentialId}
          definition={definition}
          error={props.error}
          id={sourceId}
          isSetup={props.isSetup}
          setConfig={props.setCredentialId}
          setCredentialId={props.setCredentialId}
          setTunnel={props.setTunnel}
          source={props.source}
          sourceDefinitions={sourceDefinitions}
          tunnel={props.tunnel}
          onConnectClick={props.onConnectClick}
        />
        <FieldError error={props.error} />
        <form hidden id="source-form" onSubmit={handleSubmit} />
      </FormProvider>
    </FormkitProvider>
  );
};

export type MethodProps = {
  definition: SourceDefinition;
  config: Record<string, any>;
  setConfig?: any;
  sourceDefinitions: Record<string, any>;
  id?: string;
  onConnectClick?(defintion: SourceDefinition): void;
  credentialId?: any;
  setCredentialId?: any;
  tunnel?: any;
  setTunnel?: any;
  error?: any;
  source?: any;
  isSetup?: boolean;
};

const SelectedMethodForm: FC<Readonly<MethodProps>> = ({
  definition,
  config,
  sourceDefinitions,
  id,
  onConnectClick,
  ...props
}) => {
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingSource = sourceDefinitions?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingSource?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.form)}
        </FormkitForm>
      ),
    [matchingSource?.key],
  );

  if (matchingSource?.method === "form") {
    const showTunneling =
      // Does the source form support tunneling?
      matchingSource?.tunneling &&
      // If the source is Snowflake, check that the user has the matching feature flag.
      (definition.type !== "snowflake" || (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));

    return (
      <>
        {showTunneling && <TunnelSelect optional value={props.tunnel} onChange={props.setTunnel} />}
        <CredentialSection definition={definition} matchingSource={matchingSource} {...props} />
        {Form}
      </>
    );
  } else if (matchingSource?.method === "oauth") {
    if (!id) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "18px",
            border: `1px solid ${colors.base[3]}`,
            padding: 4,
            borderRadius: 2,
          }}
        >
          <Box>Connect with OAuth</Box>
          <Link to={`${import.meta.env.VITE_API_BASE_URL}${matchingSource.url}/${definition.type}/${config?.methodKey}`}>
            <Button
              variant="secondary"
              onClick={() => {
                onConnectClick && onConnectClick(definition);
              }}
            >
              Connect
            </Button>
          </Link>
        </Box>
      );
    } else if (matchingSource?.form) {
      return (
        <>
          {Form}
          {!props.isSetup && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "18px",
                border: `1px solid ${colors.base[3]}`,
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Box>Reauthorize with OAuth</Box>
              <Link
                to={`${import.meta.env.VITE_API_BASE_URL}${matchingSource.url}/${definition.type}/${config?.methodKey}/${id}`}
              >
                <Button
                  variant="secondary"
                  onClick={() => {
                    onConnectClick && onConnectClick(definition);
                  }}
                >
                  Reauthorize
                </Button>
              </Link>
            </Box>
          )}
        </>
      );
    } else {
      return <SuccessMessage display={true} sourceName={definition.name} />;
    }
  } else {
    return null;
  }
};

const SuccessMessage: FC<Readonly<{ display: boolean; sourceName: string }>> = ({ display, sourceName }) => {
  if (display) {
    return <Message>Authorization successful! Your {sourceName} account was successfully connected.</Message>;
  } else {
    return null;
  }
};
