/**
 * More types and detail for each type can be added here as needed.
 */

type BaseErrorOriginInfo = {
  /**
   * Broadly describes where in the system the error originated.
   *
   * Different from the phase of the sync when the error occurred, since errors
   * can occur in multiple parts of the system in a given phase. For example, a
   * source query error can occur during the execution phase, since they overlap
   * due to worker pipelining.
   */
  scope: "source" | "destination";

  /**
   * What the system was doing when the error occurred.
   */
  operation: string;

  /**
   * The backend's best guess at whether Hightouch is responsible for the error.
   * Mostly informed by the type of the error.
   */
  isInternal?: boolean;
};

export type ErrorOriginInfo =
  | InternalErrorOriginInfo
  | DestinationErrorOriginInfo
  | SourceErrorOriginInfo;

export type InternalErrorOriginInfo = BaseErrorOriginInfo & {
  isInternal: true;
};

export function isInternalErrorOriginInfo(
  info: ErrorOriginInfo | undefined,
): info is InternalErrorOriginInfo {
  return info?.isInternal === true;
}

export type DestinationErrorOriginInfo = BaseErrorOriginInfo & {
  scope: "destination";
  operation:
    | "add"
    | "change"
    | "remove"
    | "archive"
    | "all"
    | "create external segment"
    | "update configuration";
};

export function isDestinationErrorOriginInfo(
  info: ErrorOriginInfo | undefined,
): info is DestinationErrorOriginInfo {
  return info?.scope === "destination";
}

export type SourceErrorOriginInfo = BaseErrorOriginInfo & {
  scope: "source";
  operation: "query";
};

export function isSourceErrorOriginInfo(
  info: ErrorOriginInfo | undefined,
): info is SourceErrorOriginInfo {
  return info?.scope === "source";
}
