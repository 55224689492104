import { FC } from "react";

import { Box, Card, Heading, Text } from "theme-ui";

import { Button } from "src/ui/button";
import { WarningIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

type Props = {
  title: string;
  subtitle?: string;
  fullScreen?: boolean;
  linkText?: string;
  to?: string;
};

export const Warning: FC<Props> = ({ title, subtitle, fullScreen, linkText, to }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: fullScreen ? 30 : 10,
      }}
    >
      <Card
        sx={{
          p: 12,
          width: "628px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WarningIcon color="base.4" size={48} />
        <Heading sx={{ fontWeight: "bold", my: 8, fontSize: "32px" }}>{title}</Heading>
        {subtitle && <Text sx={{ fontWeight: "semi", fontSize: 2, color: "base.4" }}>{subtitle}</Text>}
        {to && (
          <Link slug={false} sx={{ mt: 5 }} to={to}>
            <Button variant="secondary">{linkText}</Button>
          </Link>
        )}
      </Card>
    </Box>
  );
};
