import { useState, FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import { Text, Image, Label } from "theme-ui";

import emailSVG from "src/components/workspaces/email.svg";
import { useUser } from "src/contexts/user-context";
import { useSendInviteMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { roleDisabled } from "src/pages/settings/roles";
import { Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Select } from "src/ui/select";

interface InviteFormProps {
  name: string;
  close: () => void;
  open: boolean;
}

export const InviteFormModal: FC<Readonly<InviteFormProps>> = ({ name, close, open }) => {
  const { workspace } = useUser();
  const location = useLocation();
  const [email, setEmail] = useState<string>();
  const [invited, setInvited] = useState<boolean>(false);
  const { isLoading: inviting, mutateAsync: sendInvite } = useSendInviteMutation();

  const { authDisableNonSso, appAllowRoles } = useFlags();

  const [role, setRole] = useState<number>(workspace!.default_role_id);

  const roleOptions = workspace?.roles
    ?.sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1))
    .map((role) => {
      const disabled = roleDisabled(appAllowRoles, workspace, role);
      return {
        label: role.name,
        value: role.id,
        disabled,
      };
    });

  const handleClose = () => {
    close();
    setInvited(false);
    setEmail("");
  };

  if (authDisableNonSso) {
    return (
      <Modal
        info
        isOpen={open}
        sx={{ maxWidth: "441px", width: "100%" }}
        title={`Invite a teammate to ${name}`}
        onClose={handleClose}
      >
        Inviting teammates by email is disabled in this workspace. Please use the SSO identity provider connected to your
        organization.
      </Modal>
    );
  }

  return (
    <Modal
      footer={
        invited ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setInvited(false);
                setEmail("");
              }}
            >
              Invite more people
            </Button>
            <Button onClick={handleClose}>Done</Button>
          </>
        ) : (
          <>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!email || !workspace}
              loading={inviting}
              onClick={async () => {
                if (!email) {
                  return;
                }
                await sendInvite({ recipientEmail: email, recipientRoleID: role });
                setInvited(true);
                analytics.track("Teammate Invited", {
                  workspace_id: workspace?.id,
                  workspace_slug: workspace?.slug,
                  origin_page: location.pathname,
                });
              }}
            >
              Send invitation
            </Button>
          </>
        )
      }
      isOpen={open}
      sx={{ maxWidth: "441px", width: "100%" }}
      title={`Invite a teammate to ${name}`}
      onClose={handleClose}
    >
      {invited ? (
        <Column sx={{ alignItems: "center" }}>
          <Image src={emailSVG} />
          <Text sx={{ textAlign: "center", mt: 4 }}>
            You invited <strong>{email}</strong> to <strong>{name}</strong>.
          </Text>
        </Column>
      ) : (
        <>
          <Label htmlFor={"email"}>Email</Label>
          <Input
            name="email"
            placeholder="e.g. bob@acme.com"
            sx={{ mt: "8px", width: "100%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label htmlFor={"selectRole"} style={{ marginTop: "24px" }}>
            Role
          </Label>
          <Select
            options={roleOptions}
            placeholder={"New user role"}
            sx={{ mt: "8px", mb: "16px" }}
            value={role}
            onChange={(option) => setRole(option.value)}
          ></Select>
          <Text variant="subtle">
            Depending on your role selection, new users may be able to create workspaces and invite other team members.
          </Text>
        </>
      )}
    </Modal>
  );
};
