import { FC, ReactNode, useRef } from "react";

import { Box, Flex, ThemeUIStyleObject } from "theme-ui";

import { Label } from "../label";

export type FieldErrorProps = {
  error?: string | ReactNode | Error | null;
  sx?: ThemeUIStyleObject;
};

export type FieldProps = {
  label: string;
  name?: string;
  description?: string | ReactNode;
  help?: string | ReactNode;
  children?: string | ReactNode;
  fieldIdentifier?: string;
  required?: boolean;
  optional?: boolean;
  sx?: ThemeUIStyleObject;
  inline?: boolean;
  nested?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  reload?: () => void;
  labelSx?: ThemeUIStyleObject;
  size?: "small" | "large";
  labelComponent?: (children: ReactNode) => ReactNode;
} & FieldErrorProps;

export const Field: FC<Readonly<FieldProps>> = ({
  inline,
  label,
  name,
  error,
  help,
  optional = false,
  description,
  required,
  sx = {},
  labelSx = {},
  children,
  nested,
  icon,
  loading,
  reload,
  labelComponent,
  size,
}) => {
  const container = useRef<HTMLDivElement | null>(null);

  if (inline) {
    return (
      <Flex
        ref={container}
        sx={{
          alignItems: error ? "flex-start" : "center",
          borderBottom: "small",
          pb: 3,
          ":last-of-type": { border: "none", pb: 0 },
          ...sx,
        }}
      >
        <Flex sx={{ alignItems: "center", mr: 2 }}>
          {icon}
          <Label
            inline
            help={help}
            htmlFor={name}
            labelComponent={labelComponent}
            optional={optional}
            required={required}
            sx={{ ml: icon ? 2 : undefined, width: "100px", ...labelSx }}
          >
            {label}
          </Label>
        </Flex>
        <Flex sx={{ flexDirection: "column", flex: 1 }}>
          {children}
          {description && <Box sx={{ mb: 2, fontSize: 1, color: "base.5" }}>{description}</Box>}
          <FieldError error={error} />
        </Flex>
      </Flex>
    );
  }

  return (
    <Box sx={sx}>
      {(label || description) && (
        <Label
          description={description}
          help={help}
          htmlFor={name}
          labelComponent={labelComponent}
          loading={loading}
          optional={optional}
          reload={reload}
          required={required}
          size={size}
          sx={{ ...labelSx }}
        >
          {label}
        </Label>
      )}
      {nested ? <Box sx={{ pl: 4, pt: 2, borderLeft: "medium" }}>{children}</Box> : children}
      <FieldError error={error} />
    </Box>
  );
};

export const FieldError: FC<Readonly<FieldErrorProps>> = ({ error, sx = {} }) => {
  if (error) {
    return (
      <Box
        sx={{
          fontSize: 1,
          mt: 2,
          color: "red",
          ":first-letter": {
            textTransform: "capitalize",
          },
          ...sx,
        }}
      >
        {getErrorMessage(error)}
      </Box>
    );
  }

  return null;
};

function getErrorMessage(error: string | ReactNode | Error | null) {
  if (!error) {
    return "";
  }

  if (error instanceof Error) {
    return error.message;
  }

  // ReactNode or string
  return error;
}
