import { PureComponent, ReactNode } from "react";

import * as Sentry from "@sentry/react";
import { Text } from "theme-ui";

import { Box } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";

interface Props {
  children: ReactNode;
}

interface State {
  isCrashed: boolean;
}

export class ErrorBoundary extends PureComponent<Props, State> {
  // eslint-disable-next-line prettier/prettier
  override state = {
    isCrashed: false,
  };

  override render() {
    if (!this.state.isCrashed) {
      return this.props.children;
    }

    return (
      <Box sx={{ mt: 20, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <svg
          fill="none"
          height="48"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
          <path d="M9 9H9.01" />
          <path d="M15 9H15.01" />
          <path d="M16 14C16 14 12 14 12 14C12 14 8 14 8 14">
            <animate
              attributeName="d"
              begin="400ms"
              calcMode="spline"
              dur="1s"
              fill="freeze"
              keySplines="0.22 1 0.36 1"
              values="M16 14C16 14 12 14 12 14C12 14 8 14 8 14; M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16"
            />
          </path>
        </svg>

        <Heading sx={{ mt: 4 }}>Something went wrong...</Heading>
        <Text sx={{ mt: 2, textAlign: "center" }}>
          An error has occurred and we certainly didn't expect it.
          <br />
          We've been notified and will fix it as soon as possible.
        </Text>

        <Box sx={{ mt: 4, display: "flex" }}>
          <a href="mailto:hello@hightouch.io">
            <Button variant="secondary">Contact Support</Button>
          </a>

          <Button sx={{ ml: 3 }} onClick={this.reload}>
            Reload Page
          </Button>
        </Box>
      </Box>
    );
  }

  override componentDidCatch(error: Error) {
    this.setState({
      isCrashed: true,
    });

    Sentry.captureException(error);
  }

  reload() {
    window.location.reload();
  }
}
