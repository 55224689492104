/* eslint-disable jest/no-export */
import { useCallback, useEffect } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { SourceDefinition, useTestNewSourceMutation, useTestUpdatedSourceMutation } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Spinner } from "src/ui/loading";

export enum TestResult {
  Unknown,
  Success,
  Failed,
}

type NewSourceProperties = {
  definition: SourceDefinition;
  configuration: Record<string, unknown>;
  tunnelId: string | undefined;
  credentialId: string | undefined;
  onResult: (result: TestResult) => void;
  onError: (error: Error | null) => void;
  onLoading?: (loading: boolean) => void;
};

/**
 * Custom hook for both test source buttons.
 */
function useCommonTestSourceEffects(
  { onError, onResult, onLoading }: NewSourceProperties | UpdatedSourceProperties,
  testing,
  testResult?: { success: boolean; reason?: string | null } | null,
) {
  useEffect(() => {
    if (onLoading) {
      onLoading(testing);
    }
  }, [testing, onLoading]);

  useEffect(() => {
    if (!testResult) {
      return;
    }
    if (testResult.success) {
      onError(null);
      onResult(TestResult.Success);
      return;
    }
    onError(new Error(testResult.reason ?? ""));
    onResult(TestResult.Failed);
  }, [testResult, onError, onResult]);
}

/**
 * Performs a test connection to the source when the button is clicked, and notifies the status.
 * This is required to create a new source.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestNewSourceButton(props: Readonly<NewSourceProperties>): JSX.Element | null {
  const { definition, configuration, tunnelId, credentialId } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestNewSourceMutation();

  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        type: definition.type,
        configuration,
        tunnelId,

        // @ts-expect-error credentialId should be string in GQL
        credentialId: credentialId as number,
      }),
    [definition, configuration, tunnelId, credentialId],
  );

  useCommonTestSourceEffects(props, testing, data?.testNewSource);

  return (
    <Button loading={testing} sx={{ width: "100%" }} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

type UpdatedSourceProperties = {
  sourceId: string;
  newConfiguration: Record<string, unknown>;
  tunnelId: string | undefined;
  credentialId: string | undefined;
  onResult: (result: TestResult) => void;
  onError: (error: Error | null) => void;
  onLoading: (loading: boolean) => void;
  buttonProps?: ThemeUIStyleObject;
};

/**
 * Performs a test connection to the source when the button is clicked, and notifies the status.
 * This is not required to update a source.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestUpdatedSourceButton(props: Readonly<UpdatedSourceProperties>): JSX.Element | null {
  const { sourceId, newConfiguration, tunnelId, credentialId, buttonProps } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestUpdatedSourceMutation();
  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        sourceId,
        newConfiguration,
        tunnelId,

        credentialId:
          // @ts-expect-error credentialId should be string in GQL
          credentialId as number,
      }),
    [sourceId, newConfiguration, tunnelId, credentialId],
  );

  useCommonTestSourceEffects(props, testing, data?.testUpdatedSource);

  return (
    <Button loading={testing} sx={{ width: "100%", ...buttonProps }} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

/**
 * Displays a badge with the result of testing a source.
 *
 * @param result Result of the test.
 * @param testing If the testing is in progress.
 *
 * @returns The badge.
 */
export function TestSourceBadge({ result, testing }: Readonly<{ result: TestResult; testing?: boolean }>): JSX.Element | null {
  if (testing) {
    return (
      <Badge variant="base">
        <Spinner size={16} />
        <Text sx={{ ml: 2 }}>Connecting...</Text>
      </Badge>
    );
  }

  let enable = true;
  let color: string | undefined;
  let message: string | undefined;

  switch (result) {
    case TestResult.Success:
      color = "green";
      message = "Connected";
      break;
    case TestResult.Failed:
      color = "red";
      message = "Error";
      break;
    default:
      enable = false;
  }

  if (!enable) {
    return null;
  }

  return (
    <Badge sx={{ ml: 4 }} variant="base">
      <Circle color={color} radius="12px" />
      <Text sx={{ ml: 2 }}>{message}</Text>
    </Badge>
  );
}
