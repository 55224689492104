import { FC } from "react";

import { Box, Flex, Paragraph, Image, Text, ThemeUIStyleObject } from "theme-ui";

import { Button } from "src/ui/button";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Spinner } from "src/ui/loading";
import { Tooltip } from "src/ui/tooltip";

import { Heading } from "../../../ui/heading";

// Disabled CTA buttons show a tooltip and should have a tooltipText field
export type PricingCardProps = {
  plan?: string;
  sku: string;
  isRecommended?: boolean;
  ctaText?: string;
  ctaDisabled?: boolean;
  ctaUrl?: string;
  ctaLoading?: boolean;
  onCtaClick: (sku: string) => void;
  tooltipText?: string;
  price?: string;
  priceUnit?: string;
  priceHint?: string;
  checkListHeading?: string;
  checkList?: string[];
  partnerLogos: { img: string; alt: string }[];
  sx?: ThemeUIStyleObject;
};

export const PricingCard: FC<Readonly<PricingCardProps>> = ({
  plan,
  sku,
  isRecommended,
  ctaText,
  ctaDisabled,
  onCtaClick,
  ctaLoading,
  tooltipText = "",
  partnerLogos,
  price,
  priceHint,
  priceUnit,
  checkList,
  checkListHeading,
  sx = {},
}) => {
  function ctaClick() {
    onCtaClick(sku);
  }

  return (
    <Box
      sx={{
        py: 6,
        border: "1px solid",
        borderColor: ctaDisabled ? "base.2" : "primaries.4",
        borderRadius: 2,
        minHeight: "50vh",
        position: "relative",
        ...sx,
      }}
    >
      <Flex sx={{ flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <Box sx={{ mx: 4, position: "relative" }}>
          {/* heading */}
          <Flex sx={{ gap: 2, alignItems: "center", mb: 6 }}>
            <Heading sx={{ fontSize: 5, weight: "semi" }} variant="h2">
              {plan}
            </Heading>
            {isRecommended && <Recommended />}
          </Flex>
          {
            /* disabled CTA with tooltip */
            ctaDisabled && tooltipText.length > 0 && (
              <Tooltip hasCaret disabled={true} sx={{ width: "100%" }} text={tooltipText} theme="dark">
                <Button sx={disabledButtonSx} type="button" variant="primary">
                  {ctaText} {ctaLoading && <Spinner sx={{ pl: 2 }} />}
                </Button>
              </Tooltip>
            )
          }
          {
            /* primary CTA w/o tooltip */
            <Button
              disabled={ctaDisabled || ctaLoading}
              sx={ctaDisabled || ctaLoading ? disabledButtonSx : enabledButtonSx}
              type="button"
              variant="primary"
              onClick={ctaClick}
            >
              {ctaText} {ctaLoading && <Spinner sx={{ pl: 2 }} />}
            </Button>
          }
          {/* pricing */}
          <Paragraph sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: 3, fontWeight: "bold" }}>
            <Text>{price}</Text>
            {priceUnit && <Text sx={{ color: "base.4" }}>{priceUnit}</Text>}
          </Paragraph>
          {/* checklist */}
          <CheckList>
            <Text sx={{ fontWeight: "bold", mb: 4 }}>{checkListHeading}</Text>
            {checkList?.map((listItem) => {
              return <CheckListItem key={listItem}>{listItem}</CheckListItem>;
            })}
          </CheckList>
          {
            /* pricing hint */
            priceHint && <Text sx={{ color: "base.4" }}>{priceHint}</Text>
          }
        </Box>
        {/* partner logos */}
        <Box sx={{ p: 4, pb: 0, textAlign: "center" }}>
          <Text sx={{ color: "base.5" }}>Powering Companies Like</Text>
          {/* TODO: insert image slideshow here */}
          <Flex sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
            {partnerLogos.map((logo) => {
              return <Image key={logo.alt} alt={logo.alt} src={logo.img} width="200px" />;
            })}
            <Image />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const Recommended = () => {
  return (
    <Text sx={{ px: "10px", py: "2px", backgroundColor: "primaries.0", color: "primary", borderRadius: 4 }}>Recommended</Text>
  );
};

const disabledButtonSx: ThemeUIStyleObject = {
  width: "100%",
  mb: 6,
  fontSize: 1,
  bg: "base.1",
  color: "#6B7280 !important", // cannot use theme var with !important here so i'm using hex
  fontWeight: 400,
};

const enabledButtonSx: ThemeUIStyleObject = {
  width: "100%",
  mb: 6,
  fontSize: 1,
};

PricingCard.displayName = "Pricing Card";
