import { FC, ReactNode } from "react";

import immutableUpdate from "immutability-helper";
import { Text, ThemeUIStyleObject } from "theme-ui";

import {
  Condition,
  ConditionType,
  FilterableColumn,
  Relationship,
  TraitDefinition,
  AndOrCondition,
  Audience,
  AudienceParent,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PlusIcon, XIcon } from "src/ui/icons";

import { EventFilter } from "./event-filter";
import { NumberOfFilter } from "./number-of-filter";
import { PropertyFilter } from "./property-filter";
import { ReferencePropertyFilter } from "./reference-property-filter";
import { SegmentSetFilter } from "./segment-set-filter";

export interface CommonProps {
  audience: Audience | undefined;
  parent: AudienceParent | undefined | null;
  columns: FilterableColumn[] | undefined;
  events: Relationship[] | undefined;
  relationships: Relationship[] | undefined;
  traits: TraitDefinition[] | undefined;
  onRemove: () => void;
  isEventTrait?: boolean;
}

interface ConditionFieldProps extends CommonProps {
  condition: AndOrCondition<Condition>;
  referenceColumns?: FilterableColumn[];
  onChange: (updates: Partial<Condition>) => void;
}

export interface FilterProps<TCondition> extends CommonProps {
  condition: TCondition;
  onChange: (updates: Partial<TCondition>) => void;
}

export const ConditionField: FC<Readonly<ConditionFieldProps>> = (props) => {
  const { condition } = props;
  switch (condition.type) {
    // And/Or conditions are not currently supported.
    case ConditionType.And:
    case ConditionType.Or:
      return <ConditionField {...props} />;
    case ConditionType.SegmentSet:
      return <SegmentSetFilter {...props} condition={condition} />;
    case ConditionType.Property:
      return <PropertyFilter {...props} condition={condition} />;
    case ConditionType.ReferenceProperty:
      return <ReferencePropertyFilter {...props} condition={condition} />;
    case ConditionType.NumberOf:
      return <NumberOfFilter {...props} condition={condition} />;
    case ConditionType.Event:
      return <EventFilter {...props} condition={condition} />;
  }
};

export const HStack: FC<{ children: ReactNode; sx?: ThemeUIStyleObject; gap: number }> = ({ children, sx = {}, gap }) => (
  <Row
    sx={{
      width: "inherit",
      alignItems: "center",
      "& > *:not(:last-child)": { mr: gap },
      ...sx,
    }}
  >
    {children}
  </Row>
);

export const OperatorLabel: FC<{ children: ReactNode }> = ({ children }) => (
  <Text sx={{ color: "secondaries.8", whiteSpace: "nowrap", flexShrink: 0 }}>{children}</Text>
);

export const RemoveButton: FC<{ onRemove: () => void }> = ({ onRemove }) => (
  <Button
    sx={{ ml: 2 }}
    variant="plain"
    onClick={() => {
      onRemove();
    }}
  >
    <XIcon color="secondaries.5" size={16} strokeWidth="1px" />
  </Button>
);

export const PlusButton: FC<{ children: ReactNode; onClick: () => void; disabled?: boolean }> = ({
  children,
  onClick,
  disabled,
}) => (
  <Button disabled={disabled} iconBefore={<PlusIcon size={14} />} size="small" variant="gray" onClick={onClick}>
    {children}
  </Button>
);

export const updateSubconditions = (onChange, subconditions, index) => (updates: any) => {
  onChange({ subconditions: immutableUpdate(subconditions, { [index]: { $merge: updates } }) });
};

export const removeSubcondition = (onChange, subconditions, index) => () => {
  onChange({ subconditions: subconditions.filter((_, i) => i !== index) });
};
