/* eslint-disable jest/no-export */
import { useEffect, useCallback } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import {
  DestinationDefinitionFragment as DestinationDefinition,
  useTestNewDestinationMutation,
  useTestUpdatedDestinationMutation,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Spinner } from "src/ui/loading";

export enum TestResult {
  Unknown,
  Success,
  Failed,
}

type NewDestinationProperties = {
  definition: DestinationDefinition;
  configuration: Record<string, unknown> | undefined;
  credentialId?: string;
  result: TestResult;
  onResult: (result: TestResult) => void;
  onError: (error: Error | null) => void;
  onLoading?: (loading: boolean) => void;
};

/**
 * Custom hook for both test destination buttons.
 */
function useCommonTestDestinationEffects(
  { onError, onResult, onLoading }: NewDestinationProperties | UpdatedDestinationProperties,
  testing: boolean,
  testResult?: { success: boolean; reason?: string },
) {
  useEffect(() => {
    if (onLoading) {
      onLoading(testing);
    }
  }, [testing, onLoading]);

  useEffect(() => {
    if (!testResult?.reason && !testResult?.success) {
      return;
    }
    if (testResult.success) {
      onError(null);
      onResult(TestResult.Success);
      return;
    }
    onError(new Error(testResult.reason));
    onResult(TestResult.Failed);
  }, [testResult?.reason, testResult?.success, onError, onResult]);
}

/**
 * Performs a test connection to the destination when the button is clicked, and notifies the status.
 * This is required to create a new destination.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestNewDestinationButton(props: Readonly<NewDestinationProperties>): JSX.Element | null {
  const { definition, configuration, credentialId } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestNewDestinationMutation();

  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        type: definition.type,
        configuration,
        credentialId,
      }),
    [definition, configuration, credentialId],
  );

  useCommonTestDestinationEffects(props, testing, {
    success: data?.testNewDestination?.success || false,
    reason: data?.testNewDestination?.reason || undefined,
  });

  return (
    <Button loading={testing} sx={{ width: "100%" }} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

type UpdatedDestinationProperties = {
  destinationId: string;
  credentialId: string | undefined;
  newConfiguration: Record<string, unknown> | undefined;
  onResult: (result: TestResult) => void;
  onError: (error: Error | null) => void;
  onLoading: (loading: boolean) => void;
  buttonProps?: ThemeUIStyleObject;
};

/**
 * Performs a test connection to the destination when the button is clicked, and notifies the status.
 * This is not required to update a destination.
 *
 * @param params
 *
 * @returns A button to click and an error message.
 */
export function TestUpdatedDestinationButton(props: Readonly<UpdatedDestinationProperties>): JSX.Element | null {
  const { destinationId, newConfiguration, buttonProps, credentialId } = props;
  const { isLoading: testing, mutateAsync: test, data } = useTestUpdatedDestinationMutation();
  const startTest = useCallback(
    () =>
      // eslint-disable-next-line jest/valid-title
      test({
        destinationId,
        newConfiguration,
        credentialId,
      }),
    [destinationId, newConfiguration, credentialId],
  );

  useCommonTestDestinationEffects(props, testing, {
    success: data?.testUpdatedDestination?.success || false,
    reason: data?.testUpdatedDestination?.reason || undefined,
  });

  return (
    <Button loading={testing} sx={{ width: "100%", ...buttonProps }} variant="secondary" onClick={startTest}>
      Test connection
    </Button>
  );
}

/**
 * Displays a badge with the result of testing a destination.
 *
 * @param result Result of the test.
 * @param testing If the testing is in progress.
 *
 * @returns The badge.
 */
export function TestDestinationBadge({
  result,
  testing,
}: Readonly<{ result: TestResult; testing?: boolean }>): JSX.Element | null {
  if (testing) {
    return (
      <Badge variant="base">
        <Spinner size={16} />
        <Text sx={{ ml: 2 }}>Connecting...</Text>
      </Badge>
    );
  }

  let color: string | undefined;
  let message: string | undefined;

  switch (result) {
    case TestResult.Success:
      color = "green";
      message = "Connected";
      break;
    case TestResult.Failed:
      color = "red";
      message = "Error";
      break;
    default:
      return null;
  }

  return (
    <Badge sx={{ ml: 4 }} variant="base">
      <Circle color={color} radius="12px" />
      <Text sx={{ ml: 2 }}>{message}</Text>
    </Badge>
  );
}
