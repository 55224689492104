import { FC } from "react";

import { Text } from "theme-ui";

import {
  EventCondition,
  FunnelCondition,
  FunnelValueOptions,
  initialPropertyCondition,
  IntervalUnit,
  TimestampOperator,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { FilterIcon } from "src/ui/icons/filter";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import {
  ConditionField,
  FilterProps,
  HStack,
  PlusButton,
  RemoveButton,
  removeSubcondition,
  updateSubconditions,
} from "./condition";
import { WindowFilter } from "./window-filter";

type FunnelFilterProps = FilterProps<FunnelCondition> & { eventCondition: EventCondition };

export const FunnelFilter: FC<Readonly<FunnelFilterProps>> = (props) => {
  const { events, eventCondition, condition, onChange, onRemove } = props;

  const eventName = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model?.name;

  const eventOptions = events
    ?.filter(({ to_model: { id } }) => id !== eventCondition.eventModelId)
    ?.map(({ id: relationshipId, name, to_model: { id: eventModelId } }) => ({
      label: name,
      value: eventModelId,
      relationshipId,
    }));

  return (
    <>
      <HStack gap={2}>
        <Row sx={{ alignItems: "center", color: "secondaries.10" }}>
          <FilterIcon color="secondaries.10" size={14} />
          <Text sx={{ ml: 2 }}>{condition.didPerform ? "and then did perform" : "and then did not perform"}</Text>
        </Row>

        <AttributeSelect
          options={eventOptions}
          placeholder="Select an event"
          value={condition.eventModelId}
          onChange={(eventModelId, { relationshipId }) => {
            onChange({
              relationshipId,
              eventModelId,
            });
          }}
        />

        <RemoveButton onRemove={onRemove} />
      </HStack>
      {condition.window && <WindowFilter condition={condition} eventName={eventName} onChange={onChange} />}

      {condition.subconditions?.map((subcondition, index) => {
        const update = updateSubconditions(onChange, condition.subconditions, index);
        const funnelColumns = events?.find(({ id }) => id === condition.relationshipId)?.to_model?.filterable_audience_columns;
        const referenceColumns = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model
          ?.filterable_audience_columns;
        return (
          <Row key={index} sx={{ alignItems: "center", pl: 12 }}>
            <Text sx={{ flexShrink: 0, color: "base.4", width: "3.5rem" }}>{index === 0 ? "WHERE" : "AND"}</Text>
            <NewSelect
              options={FunnelValueOptions}
              sx={{ mr: 2 }}
              value={subcondition.type}
              width={180}
              onChange={(type) =>
                update({
                  type,
                  value: null,
                  valueFromColumn: null,
                })
              }
            />
            <ConditionField
              {...props}
              columns={funnelColumns}
              condition={subcondition}
              referenceColumns={referenceColumns}
              traits={[]}
              onChange={update}
              onRemove={removeSubcondition(onChange, condition.subconditions, index)}
            />
          </Row>
        );
      })}
      <HStack gap={4} sx={{ pl: 12 }}>
        <PlusButton
          disabled={!condition.eventModelId}
          onClick={() => {
            onChange({ subconditions: [...(condition.subconditions ?? []), initialPropertyCondition] });
          }}
        >
          property
        </PlusButton>
        {!condition.window && (
          <PlusButton
            disabled={!condition.eventModelId}
            onClick={() => {
              onChange({
                window: {
                  operator: TimestampOperator.Within,
                  value: { interval: IntervalUnit.Day, quantity: 7 },
                },
              });
            }}
          >
            time window
          </PlusButton>
        )}
      </HStack>
    </>
  );
};
