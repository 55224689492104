import { FC } from "react";

import { Warning } from "src/components/warning";

const WorkspaceNotFoundPage: FC = () => {
  return (
    <Warning
      fullScreen
      linkText="Select a workspace"
      subtitle="The workspace you're looking for may not exist. Please check the url."
      title="Workspace not found"
      to="/workspaces"
    />
  );
};

export default WorkspaceNotFoundPage;
