import { FC } from "react";

import { useLocation } from "react-router-dom";
import { Grid, Image, Text } from "theme-ui";

import googleLogo from "src/components/logos/google.svg";
import azureLogo from "src/components/logos/microsoft.svg";
import { usePartnerConnectGetInformationQuery } from "src/graphql";
import { PartnerConnectLogo } from "src/pages/partner-connect";
import { Fade } from "src/ui/animations";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { CheckCircleIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { useQueryString } from "src/utils/use-query-string";

interface LocationState {
  from?: {
    pathname: string;
  };
}

export const Continue: FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState | undefined;
  const {
    data: { returnTo: queryLocation },
  } = useQueryString();
  const returnTo = queryLocation || locationState?.["from"]?.["pathname"];
  const match = returnTo?.match(/^\/?partner-connect\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/i);
  const { data: partnerInformation } = usePartnerConnectGetInformationQuery(
    { uuid: match?.[1] ?? "" },
    { enabled: Boolean(match?.length), select: (data) => data.partnerConnectGetInformation, suspense: true },
  );

  const googleAuthUrl = new URL(`${import.meta.env.VITE_API_BASE_URL}/google/oauth`);
  const azureAuthUrl = new URL(`${import.meta.env.VITE_API_BASE_URL}/azure/oauth`);

  if (returnTo) {
    googleAuthUrl.searchParams.append("returnTo", returnTo);
    azureAuthUrl.searchParams.append("returnTo", returnTo);
  }

  return (
    <Card sx={{ maxWidth: "550px", width: "100%" }}>
      <Fade sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
        <Header partnerInformation={partnerInformation} />
        <Link sx={{ width: "100%", mb: 4 }} to={googleAuthUrl.toString()}>
          <Button size="large" sx={{ width: "100%" }} variant="white">
            <Image src={googleLogo} sx={{ width: "20px" }} />
            <Text sx={{ width: "200px", textAlign: "left", ml: 4 }}>Continue with Google</Text>
          </Button>
        </Link>
        <Link sx={{ width: "100%", mb: 4 }} to={azureAuthUrl.toString()}>
          <Button size="large" sx={{ width: "100%" }} variant="white">
            <Image src={azureLogo} sx={{ width: "20px" }} />
            <Text sx={{ width: "200px", textAlign: "left", ml: 4 }}>Continue with Microsoft</Text>
          </Button>
        </Link>
        <Link slug={false} sx={{ width: "100%", mb: 6 }} to={returnTo ? `/sso?returnTo=${returnTo}` : "/sso"}>
          <Button propagate label="Enterprise Sign-in" size="large" sx={{ width: "100%" }} variant="white" />
        </Link>
        <Link sx={{ fontWeight: "bold", color: "base.5", fontSize: 2 }} to="email">{`Continue with Email`}</Link>
        <Terms />
      </Fade>
    </Card>
  );
};

export const Header: FC<Readonly<{ partnerInformation?: any }>> = ({ partnerInformation }) => {
  if (partnerInformation) {
    return (
      <>
        <Text
          sx={{
            fontWeight: "bold",
            fontSize: 7,
            color: "secondary",
            mb: 8,
            width: "100%",
            textAlign: "center",
          }}
        >
          Connect {partnerInformation.partnerName}
        </Text>

        <PartnerConnectLogo logo={partnerInformation.partnerLogo} name={partnerInformation.partnerName} />

        <Grid gap={2} sx={{ p: 3, mb: 10 }}>
          {partnerInformation.resources.map((resource) => (
            <Row key={resource} sx={{ alignItems: "center" }}>
              <CheckCircleIcon color="base.3" size={20} sx={{ flexShrink: 0, mr: 2 }} />
              <Text sx={{ fontWeight: "semi", color: "base.7" }}>Connect your {resource.split(" ").slice(1).join(" ")}</Text>
            </Row>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Text sx={{ fontWeight: "bold", fontSize: 7, color: "secondary", mb: 8 }}>Welcome to Hightouch</Text>
      <Text sx={{ fontWeight: "bold", fontSize: 2, color: "base.5", mb: 10 }}>Activate your data</Text>
    </>
  );
};

export const Terms: FC = () => (
  <Text sx={{ color: "base.4", lineHeight: "24px", mt: 10 }}>
    By using Hightouch you are agreeing to our{" "}
    <Link newTab sx={{ color: "base.7" }} to={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`}>
      privacy policy
    </Link>{" "}
    and{" "}
    <Link newTab sx={{ color: "base.7" }} to={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`}>
      terms
    </Link>
    .
  </Text>
);
