import { useMemo, FC } from "react";

import { isIntervalType, isTimeRangeType, TimeRangeValue, TimeType } from "@hightouch/lib/query/visual/types";
import * as Sentry from "@sentry/browser";
import { isEqual, uniq } from "lodash";
import pluralize from "pluralize";
import { Text } from "theme-ui";

import { useColumnSuggestionsQuery } from "src/graphql";
import {
  ColumnReference,
  ColumnType,
  DefaultOperators,
  FilterableColumn,
  getInitialTraitColumn,
  IntervalOperators,
  IntervalValue,
  isColumnReference,
  isRelatedColumn,
  isTraitColumn,
  OperatorOptions,
  OperatorsWithoutValue,
  PropertyCondition,
  TimestampOperator,
  TraitDefinition,
  TraitType,
  RawSqlTraitConfig,
} from "src/types/visual";
import { TraitIcon } from "src/ui/icons";
import { NewSelect } from "src/ui/new-select";
import { formatDateOrDatetime } from "src/utils/time";

// eslint-disable-next-line import/no-absolute-path

import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack, OperatorLabel, RemoveButton } from "./condition";
import { Filter } from "./filter";
import { PropertyInput } from "./property-input";
import { TraitFilter } from "./trait-filter";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = (props) => {
  const { columns, condition, onChange, onRemove, parent, traits } = props;

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const modelIds = useMemo(() => getAllModelIds(String(parent?.id), columns), [parent?.id, columns]);

  const { data: suggestionColumnsData, isLoading: loadingSuggestions } = useColumnSuggestionsQuery({
    ids: modelIds,
  });

  const suggestions = suggestionColumnsData?.getTopK?.columns?.find?.(({ modelId, name }) =>
    isColumnReference(condition.property)
      ? modelId === String(getModelIdFromColumn(condition.property)) && name === getPropertyNameFromColumn(condition.property)
      : modelId === String(parent?.id) && name === condition.property,
  )?.values;

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship.to_model.name) || []),
  ]);
  const isTrait = isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(({ model_name }) => model_name === model);
          const traitsForModel = traits?.filter((trait) => trait.relationship.to_model.name === model);
          const traitOptions = getTraitOptions(condition.property, traitsForModel);
          return {
            label: model,
            options: [...traitOptions, ...getColumnOptions(modelColumns ?? [], isDeprecatedPropertyCondition)],
          };
        })
      : [...getTraitOptions(condition.property, traits), ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition)];

  const operatorOptions = condition.propertyType ? OperatorOptions[condition.propertyType] : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;
  const formattedValue = formatValue(condition, condition.value);

  const propertyFilter = (
    <>
      {condition.property && (
        <Filter
          content={
            <>
              <NewSelect
                options={operatorOptions}
                placeholder="Filter on"
                sx={{ flex: "0 0 auto" }}
                value={condition.operator}
                width={200}
                onChange={(value) => {
                  if (
                    (IntervalOperators.includes(condition.operator) && !IntervalOperators.includes(value)) ||
                    OperatorsWithoutValue.includes(value) ||
                    [condition.operator, value].includes(TimestampOperator.Between)
                  ) {
                    onChange({ operator: value, value: null });
                  } else {
                    onChange({ operator: value });
                  }
                }}
              />
              <PropertyInput {...props} suggestions={suggestions} />
            </>
          }
        >
          <OperatorLabel>{operatorLabel}</OperatorLabel>
          {formattedValue}
        </Filter>
      )}
      <RemoveButton onRemove={onRemove} />
    </>
  );

  return (
    <>
      <HStack gap={2}>
        <AttributeSelect
          disabled={loadingSuggestions}
          loading={loadingSuggestions}
          options={propertyOptions}
          placeholder="Select a property"
          value={condition.property}
          onChange={(value, { type }) => {
            onChange({
              propertyType: type,
              property: value,
              operator: DefaultOperators[type],
              value: null,
            });
          }}
        />
        {!isTrait && propertyFilter}
      </HStack>
      {isTrait && (
        <>
          <TraitFilter {...props} condition={condition} />
          <HStack gap={2}>{propertyFilter}</HStack>
        </>
      )}
    </>
  );
};

export const getColumnOptions = (columns: FilterableColumn[], isDeprecatedPropertyCondition?: boolean) => {
  return columns.map(({ alias, name, type, custom_type, column_reference }) => ({
    value: isDeprecatedPropertyCondition ? getPropertyNameFromColumn(column_reference) : column_reference,
    label: alias || name,
    type: custom_type || type,
  }));
};

const getModelIdFromColumn = (column: ColumnReference) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.modelId;
  } else if (column.type === "related") {
    // XXX: The `any` is just so that we can merge the backend first. Sean's
    // frontend traits implementation will fix it.
    return getModelIdFromColumn(column.column as any);
  }
};

const getPropertyNameFromColumn = (column: ColumnReference) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.name;
  } else if (column.type === "related") {
    // XXX: The `any` is just so that we can merge the backend first. Sean's
    // frontend traits implementation will fix it.
    return getPropertyNameFromColumn(column.column as any);
  }
};

const formatValue = (condition: PropertyCondition, value: any) => {
  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }
  if (value === null) {
    return <Text>any value</Text>;
  }
  if (Array.isArray(value)) {
    return (
      <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{value.map(String).join(", ")}</Text>
    );
  }
  if (condition.propertyType === ColumnType.Date || condition.propertyType === ColumnType.Timestamp) {
    if (typeof value === "object") {
      return condition.propertyType === ColumnType.Date
        ? getDateValue(value, condition.timeType)
        : getTimeValue(value, condition.timeType);
    }

    return (
      <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {formatDateOrDatetime(value, condition.propertyType !== ColumnType.Date)}
      </Text>
    );
  }
  return <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{String(value)}</Text>;
};

const getAllModelIds = (parentModelId: string, columns: FilterableColumn[] | undefined) => {
  const modelIdsFromColumns = columns
    ?.filter(({ column_reference }) => column_reference)
    ?.map(({ column_reference }) => getModelIdFromColumn(column_reference))
    ?.filter(Boolean)
    ?.map(String);

  return uniq([String(parentModelId), ...(modelIdsFromColumns || [])]);
};

const getTraitPropertyType = (trait: TraitDefinition) => {
  if (
    trait.type === TraitType.MostFrequent ||
    trait.type === TraitType.LeastFrequent ||
    trait.type === TraitType.First ||
    trait.type === TraitType.Last
  ) {
    const columnReference = trait.config.toSelect;
    const column = trait.relationship.to_model.filterable_audience_columns.find(({ column_reference }) =>
      isEqual(column_reference, columnReference),
    );
    return column?.type;
  } else if (trait.type === TraitType.Count || trait.type === TraitType.Sum) {
    return "number";
  } else if (trait.type === TraitType.RawSql) {
    return (trait.config as RawSqlTraitConfig).resultingType;
  } else {
    return undefined;
  }
};

const getTraitOptions = (property: PropertyCondition["property"], traits: TraitDefinition[] | undefined) => {
  if (traits) {
    return traits
      .filter((trait) => {
        const traitHasType = Boolean(trait.type);

        if (!traitHasType) {
          Sentry.captureMessage("Trait missing type", { tags: { trait_id: trait.id } });
        }

        return traitHasType;
      })
      .map((trait) => ({
        label: trait.name,
        value:
          isRelatedColumn(property) && isTraitColumn(property.column) && property.column.traitDefinitionId === trait.id
            ? property
            : getInitialTraitColumn(trait),
        operator: "exists",
        type: getTraitPropertyType(trait),
        render: () => (
          <>
            <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
            <Text sx={{ whiteSpace: "nowrap" }}>{trait.name}</Text>
          </>
        ),
      }));
  }
  return [];
};

const isCompletedInterval = (intervalValue: IntervalValue) => {
  return intervalValue.interval && intervalValue.quantity;
};

const isCompletedTimeRange = (timeRangeValue: TimeRangeValue) => {
  const { before, after } = timeRangeValue;
  return [before, after].every((v) => typeof v === "string" || (isIntervalType(v) && isCompletedInterval(v)));
};

const TimeText = ({ value, timeType, hideTime }: { value: any; timeType: TimeType | undefined; hideTime?: boolean }) => {
  if (timeType === "absolute") {
    return (
      <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {formatDateOrDatetime(value, !hideTime)}
      </Text>
    );
  }
  return <Text>{pluralize(value.interval, value.quantity, true)}</Text>;
};

export const getDateValue = (value: any, timeType: TimeType | undefined, funnelCondition?: boolean) => {
  return getTimeValue(value, timeType, funnelCondition, true);
};

export const getTimeValue = (value: any, timeType: TimeType | undefined, funnelCondition?: boolean, hideTime?: boolean) => {
  if (isTimeRangeType(value) && isCompletedTimeRange(value)) {
    return (
      <>
        <TimeText hideTime={hideTime} timeType={timeType} value={value.before} />
        <Text sx={{ color: "base.5" }}>and</Text>
        <TimeText hideTime={hideTime} timeType={timeType} value={value.after} />
        {!funnelCondition && timeType === "relative" && <Text sx={{ color: "base.5" }}>ago</Text>}
      </>
    );
  }

  if (isIntervalType(value) && isCompletedInterval(value)) {
    return <Text>{pluralize(value.interval, value.quantity, true)} ago</Text>;
  }

  return null;
};
