import { FC } from "react";

import { BillingOrganizationQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { XIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

import { PricingDisplay } from "../pricing/pricing-display";

export type PricingModalProps = {
  organization: BillingOrganizationQuery["getBillingOrganization"] | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const PricingModal: FC<Readonly<PricingModalProps>> = ({ isOpen, onClose, organization }) => {
  return (
    <Modal
      bodySx={{ position: "relative", py: 5, px: 7, bg: "white" }}
      headerSx={{ display: "none" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Button
        sx={{
          position: "absolute",
          top: 3,
          right: 3,
          border: "none",
        }}
        type="button"
        variant="secondary"
        onClick={onClose}
      >
        <XIcon color="base.4" />
      </Button>
      <PricingDisplay organization={organization} />
    </Modal>
  );
};
