import { FC } from "react";

import { useClipboard } from "use-clipboard-copy";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { CopyIcon, CheckIcon } from "src/ui/icons";

import { Input } from "./input";

type Props = {
  value: string;
};

export const CopyInput: FC<Readonly<Props>> = ({ value }) => {
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });

  const copy = () => {
    clipboard.copy(value);
  };

  return (
    <Row sx={{ alignItems: "center", position: "relative" }}>
      <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={value} />
      <Button
        sx={{ position: "absolute", right: 0, width: "40px", bg: "base.2", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        variant="plain"
        onClick={copy}
      >
        {clipboard.copied ? <CheckIcon color="green" size={18} /> : <CopyIcon size={16} />}
      </Button>
    </Row>
  );
};
