import { useState, FC } from "react";

import { groupBy } from "lodash";
import { Grid } from "theme-ui";

import { Settings } from "src/components/settings";
import { DestinationTiles } from "src/components/settings/destination-tiles";
import { FeatureCatalog } from "src/components/settings/feature-catalog";
import { WorkspaceMetricsTable, WorkspaceMetrics } from "src/components/settings/workspace-metrics-table";
import { useUser } from "src/contexts/user-context";
import { useDestinationDefinitionsQuery, useOrganizationDestinationTypesQuery, useOrganizationMetricsQuery } from "src/graphql";
import { Box } from "src/ui/box";
import { Button } from "src/ui/button";
import { Label } from "src/ui/label";

export const Usage: FC = () => {
  const { workspace } = useUser();
  const { data: organizationMetrics, isLoading: loadingOrgUsage } = useOrganizationMetricsQuery();
  const { data: destinationTypeData, isLoading: loadingDestinationTypes } = useOrganizationDestinationTypesQuery();
  const { data: definitionsData, isLoading: definitionsLoading } = useDestinationDefinitionsQuery();

  const [showAllWorkspaces, setShowAllWorkspaces] = useState(false);
  const toggleAllWorkspaces = () => {
    setShowAllWorkspaces((prev) => !prev);
  };

  let usageMetrics: WorkspaceMetrics[] = organizationMetrics?.getOrganizationMetrics?.metrics || [];
  const usageDataLength = usageMetrics.length;
  if (!showAllWorkspaces && usageDataLength > 5) {
    usageMetrics = usageMetrics.slice(0, 5);
  }

  const rolesByWorkspaceId = groupBy(organizationMetrics?.getOrganizationMetrics?.roles, "workspace_id");

  usageMetrics.forEach((workspace) => {
    workspace.roles = rolesByWorkspaceId[workspace.id] || [];
  });

  return (
    <Settings route="usage">
      <Grid gap={12}>
        <Box sx={{ marginTop: 12 }}>
          <Label size="large" sx={{ marginBottom: 0 }}>
            Workspaces {workspace?.organization ? ` in ${workspace.organization.name}` : ""}
          </Label>
          <WorkspaceMetricsTable isLoading={loadingOrgUsage} metrics={usageMetrics} />
          {usageDataLength > 5 && (
            <Box ml={3} mt={1}>
              <Button variant="text-primary" onClick={toggleAllWorkspaces}>
                {showAllWorkspaces ? "Show Less" : "Show More"}
              </Button>
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: 12 }}>
          <Label size="large" sx={{ marginBottom: 0 }}>
            Destinations
          </Label>
          <DestinationTiles
            definitions={definitionsData}
            destinationTypes={destinationTypeData}
            isLoadingDefinitions={definitionsLoading}
            isLoadingDestinationTypes={loadingDestinationTypes}
          />
        </Box>

        <Box sx={{ marginTop: 12 }}>
          <Label size="large" sx={{ marginBottom: 0 }}>
            Platform Features
          </Label>
          <FeatureCatalog />
        </Box>
      </Grid>
    </Settings>
  );
};
