import { FC, useCallback } from "react";

import { Page } from "src/components/layout";
import { SequenceStatusBadge } from "src/components/sequences/sequence-status-badge";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant, SyncSequencesOrderBy, useSequencesQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useNavigate } from "src/utils/navigate";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
  LastRunAt = "last_run_at",
}

export const Sequences: FC = () => {
  const navigate = useNavigate();

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncSequencesOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { data, isLoading: loading, error: sequencesError } = useSequencesQuery({ orderBy, limit, offset });

  const sequences = data?.sync_sequences;
  const sequencesCount = data?.sync_sequences_aggregate?.aggregate?.count ?? 0;

  const onRowClick = useCallback(({ id }, event) => openUrl(`/sequences/${id}`, navigate, event), [navigate]);

  const columns: TableColumn[] = [
    {
      name: "Status",
      key: "runs.[0].status",
      cell: (lastRunStatus) => <SequenceStatusBadge status={lastRunStatus} />,
    },
    {
      key: "name",
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
    },
    {
      key: "last_run_at",
      name: "Last run",
      sortDirection: orderBy?.last_run_at,
      onClick: () => onSort(SortKeys.LastRunAt),
      cell: (timestamp) => formatDatetime(timestamp),
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Page crumbs={[{ label: "Sequences", link: "/sequences" }]} size="full">
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
          <Heading>Sequences</Heading>
          <Row sx={{ alignItems: "center", gap: 4 }}>
            <Link to="new">
              <Button
                propagate
                onClick={() => {
                  analytics.track("Add Sequence Clicked");
                }}
              >
                Add sequence
              </Button>
            </Link>
          </Row>
        </Row>
        <Table
          columns={columns}
          data={sequences}
          error={Boolean(sequencesError)}
          placeholder={tablePlaceholder}
          onRowClick={onRowClick}
        />
        <Pagination count={sequencesCount} label="sequences" page={page} rowsPerPage={limit} setPage={setPage} />
      </PermissionProvider>
    </Page>
  );
};

const tablePlaceholder = {
  title: "No sequences",
  body: "Add a sequence to get started",
  error: "Sequences failed to load, please try again.",
};
