import { FC, useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";
import { string, object, number } from "yup";

import { Help } from "src/components/help";
import { Settings } from "src/components/settings";
import { useUser } from "src/contexts/user-context";
import {
  useExternalBucketsQuery,
  useCreateExternalBucketMutation,
  useUpdateExternalBucketMutation,
  useTestStorageQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Label } from "src/ui/label";
import { Spinner } from "src/ui/loading";
import { RadioGroup } from "src/ui/radio";

import { SelectCredential } from "../../components/credentials";
import { Code } from "../../ui/code";
import { Message } from "../../ui/message";
import { Select } from "../../ui/select";
import AWS_REGION_OPTIONS from "../../utils/aws-region-options";

enum StorageType {
  "S3" = "s3",
  "GCP" = "gcp",
}

export const Storage: FC = () => {
  const client = useQueryClient();
  const { data: bucketData, isLoading } = useExternalBucketsQuery();
  const { data: entitlementsData } = useEntitlements(false);
  const externalStorageEnabled = entitlementsData.entitlements.externalStorage;

  const bucket = bucketData?.external_buckets?.[0];
  const config = bucket?.config;
  const credential = bucket?.cloud_credential;
  const [type, setType] = useState<StorageType>(StorageType.S3);

  // Feature flag external bucket storage if the user does not already have it set up.
  const testConfig = async ({ type, config, credentialId }) => {
    const variables = { type, config: JSON.stringify(config), credentialId };

    try {
      const { testStorageConnection } = await client.fetchQuery(useTestStorageQuery.getKey(variables), {
        queryFn: useTestStorageQuery.fetcher(variables),
      });
      return { success: Boolean(testStorageConnection), error: "" };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  useEffect(() => {
    if (bucket) {
      setType(bucket?.type as StorageType);
    }
  }, [bucket]);

  if (isLoading) {
    return (
      <Settings route="storage">
        <Container size="medium">
          <Row sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Spinner size={64} />
          </Row>
        </Container>
      </Settings>
    );
  }

  if (!externalStorageEnabled && !bucket) {
    const openIntercom = () => {
      const intercom = window["Intercom"];

      if (typeof intercom === "function") {
        intercom("showNewMessage", "Hi, I'm interested in trying out the Business-tier external storage feature.");
      }
    };

    return (
      <Settings route="storage">
        <Row sx={{ height: "100%" }}>
          <Text sx={{ fontSize: 1, color: "base.5", lineHeight: 1.75 }}>
            We're glad that you want to use this feature, but External Storage buckets are only available under our{" "}
            <a href={`${import.meta.env.VITE_WEBSITE_URL}/pricing/`}>Business plan</a>. Please{" "}
            <a onClick={openIntercom}>reach out</a> to our Customer Success team if you'd like to try it out.
          </Text>
        </Row>
      </Settings>
    );
  }

  return (
    <Settings route="storage">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        {config && (
          <Message sx={{ width: "100%", maxWidth: "100%", mb: 8 }}>
            If you need to change your storage location, please reach out to our customer success team.
          </Message>
        )}

        <Label description="Store your query results in your own cloud." size="large">
          External Storage
        </Label>

        <Grid gap={8}>
          <RadioGroup
            disabled={!!config}
            options={[
              { label: "Amazon S3", value: StorageType.S3 },
              { label: "Google Cloud Storage", value: StorageType.GCP },
            ]}
            value={type}
            onChange={(value) => setType(value as StorageType)}
          />

          {type === "s3" ? (
            <S3Form config={config} credential={credential} />
          ) : (
            <GCPForm config={config} credential={credential} testConfig={testConfig} />
          )}
          <Help docs={`${import.meta.env.VITE_DOCS_URL}/security/storage`} label="External Cloud Storage" />
        </Grid>
      </Container>
    </Settings>
  );
};

const S3Form: FC<Readonly<{ config: any; credential: { id: number; stripped_config?: any } | null | undefined }>> = ({
  config,
  credential,
}) => {
  const { addToast } = useToasts();
  const { workspace } = useUser();

  const validationSchema = object().shape({
    bucket: string().required("A bucket is required."),
    region: string().required("A region is required."),
    credentialId: number().required("A credential ID is required."),
  });

  const { register, control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...config, credentialId: credential?.id },
  });

  const { isLoading: creating, mutateAsync: createExternalBucket } = useCreateExternalBucketMutation();
  const { isLoading: updating, mutateAsync: updateExternalBucket } = useUpdateExternalBucketMutation();

  const loading = creating || updating;

  const submit = async ({ bucket, region, credentialId }) => {
    const newConfig = {
      bucket,
      region,
    };

    if (config) {
      await updateExternalBucket({
        workspaceId: workspace?.id,
        append: {
          config: newConfig,
        },
        set: {
          type: StorageType.S3,
          credential_id: credentialId,
        },
      });
    } else {
      await createExternalBucket({
        object: {
          type: StorageType.S3,
          credential_id: credentialId,
          config: newConfig,
        },
      });
    }

    addToast(`Amazon S3 configuration saved!`, {
      appearance: "success",
    });
  };

  return (
    <>
      <Field label="Region">
        <Controller
          control={control}
          name={"region"}
          render={({ field }) => (
            <Select
              {...field}
              disabled={!!config}
              options={AWS_REGION_OPTIONS}
              placeholder="Select a region..."
              onChange={(selected) => field.onChange(selected.value)}
            />
          )}
        />
      </Field>
      <Field label="Bucket Name">
        <Input {...register("bucket")} disabled={!!config} />
      </Field>
      <Field label="AWS Credentials">
        <Controller
          control={control}
          name={"credentialId"}
          render={({ field }) => <SelectCredential {...field} provider={"aws"} />}
        />
      </Field>
      <Button label="Save" loading={loading} onClick={handleSubmit(submit)} />
    </>
  );
};

const GCPForm: FC<
  Readonly<{
    config: any;
    credential: { id: number; stripped_config?: any } | null | undefined;
    testConfig: ({ type, config, credentialId }) => Promise<{ success: boolean; error: string }>;
  }>
> = ({ config, credential, testConfig }) => {
  const { addToast } = useToasts();
  const { workspace } = useUser();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const validationSchema = object().shape({
    bucketName: string().required("A bucket is required."),
    projectId: string().required("A project ID is required."),
    credentialId: number().required("A credential ID is required."),
  });

  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: config
      ? {
          ...config,
          credentialId: credential?.id,
        }
      : undefined,
  });

  const { mutateAsync: createExternalBucket } = useCreateExternalBucketMutation();
  const { mutateAsync: updateExternalBucket } = useUpdateExternalBucketMutation();

  const submit = async ({ bucketName, projectId, credentialId }) => {
    setSaving(true);
    setError("");

    const newConfig = { bucketName, projectId };

    const { success, error } = await testConfig({
      type: StorageType.GCP,
      config: newConfig,
      credentialId,
    });

    if (success) {
      if (config) {
        await updateExternalBucket({
          workspaceId: workspace?.id,
          append: {
            config: newConfig,
          },
          set: {
            type: StorageType.GCP,
            credential_id: credentialId,
          },
        });
      } else {
        await createExternalBucket({
          object: {
            type: StorageType.GCP,
            config: newConfig,
            credential_id: credentialId,
          },
        });
      }

      addToast(`Google Cloud Storage configuration saved!`, { appearance: "success" });
    } else {
      setError(error);
    }

    setSaving(false);
  };

  return (
    <>
      <Field label="Project ID">
        <Input {...register("projectId")} disabled={!!config} />
      </Field>

      <Field label="Bucket Name">
        <Input {...register("bucketName")} disabled={!!config} />
      </Field>

      <Field label="Google Cloud Credentials">
        <Controller
          control={control}
          name={"credentialId"}
          render={({ field }) => <SelectCredential {...field} provider={"gcp"} />}
        />
      </Field>

      {config && credential && credential.stripped_config && (
        <Field
          description={
            "Run these two commands in your Google Cloud Shell to grant the service account access to Google Cloud Storage."
          }
          label="Authentication commands"
        >
          <Code title="Grant read access">
            <div>gcloud projects add-iam-policy-binding {config?.projectId} \ </div>
            <div>--member serviceAccount:{credential.stripped_config.client_email} \ </div>
            <div>--role roles/storage.objectViewer</div>
          </Code>
          <br />

          <Code title="Grant write access">
            <div>gcloud projects add-iam-policy-binding {config?.projectId} \ </div>
            <div>--member serviceAccount:{credential.stripped_config.client_email} \ </div>
            <div>--role roles/storage.objectCreator</div>
          </Code>
        </Field>
      )}

      <FieldError error={error} />
      <Button label="Save" loading={saving} onClick={handleSubmit(submit)} />
    </>
  );
};
