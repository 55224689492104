import { Fragment, FC } from "react";

import { Text, ThemeUIStyleObject, Message, Flex } from "theme-ui";

import { ShieldIcon } from "src/ui/icons";

import { colors } from "../../../../design/colors";
import { useUser } from "../../contexts/user-context";
import { whitelistAddresses } from "../../utils/network-details";

const commaSeparateComponents = (items: string[], itemSx?: ThemeUIStyleObject) => {
  return items.map((item, index) => (
    <Fragment key={index}>
      {index > 0 && ", "}
      <Text
        sx={{
          display: "inline",
          ...itemSx,
        }}
      >
        {item}
      </Text>
    </Fragment>
  ));
};

export const IPWhitelistMessage: FC<Readonly<{ sx?: ThemeUIStyleObject }>> = ({ sx = {} }) => {
  const { region } = useUser();

  return (
    <Message
      sx={{
        width: "unset",
        maxWidth: "unset",
        bg: "base.1",
        borderRadius: 1,
        p: 4,
        border: `1px solid ${colors.base[2]}`,
        ...sx,
      }}
    >
      <Flex>
        <ShieldIcon color="base.6" />
        <Text color="base.6" ml={2} sx={{ fontSize: "13px" }}>
          Hightouch will always connect to your warehouse from the following IPs:{" "}
          {commaSeparateComponents(whitelistAddresses[region], { fontWeight: "bold" })}. Make sure to allow inbound traffic from
          these IPs in your firewall, and include it in any database grants.
        </Text>
      </Flex>
    </Message>
  );
};
