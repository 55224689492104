import { MouseEventHandler, useEffect, useState, FC } from "react";

import pluralize from "pluralize";
import { Image, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { ChartIcon, ExternalLinkIcon, InfoIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Table, TableColumn } from "src/ui/table";
import { Tabs } from "src/ui/tabs";

import {
  ListAllSourceDependenciesQuery,
  useDestinationDefinitionsQuery,
  useListAllSourceDependenciesQuery,
  useSourceDefinitionsQuery,
} from "../../graphql";
import { Link } from "../../ui/link";

type Props = {
  isOpen?: boolean;
  loading?: boolean;
  sources: string[];
  onCancel(args?: Parameters<MouseEventHandler>[0]): void;
  onDelete(args?: Parameters<MouseEventHandler>[0]): void;
  workspaceName: string;
};

type MergedSourceDependencies = {
  models: NonNullable<ListAllSourceDependenciesQuery["listAllSourceDependencies"]["sources"][0]>["dependencies"]["models"];
  syncs: NonNullable<ListAllSourceDependenciesQuery["listAllSourceDependencies"]["sources"][0]>["dependencies"]["syncs"];
  sources: { id: string }[];
};

const mergeSourceDependencies = (sourceDependencies: ListAllSourceDependenciesQuery["listAllSourceDependencies"]) => {
  const mergedSourceDependencies: MergedSourceDependencies = {
    models: [],
    syncs: [],
    sources: [],
  };
  sourceDependencies.sources.filter(isPresent).forEach((source) => {
    source.dependencies.models.forEach((model) => {
      mergedSourceDependencies.models.push(model);
    });
    source.dependencies.syncs.forEach((sync) => {
      mergedSourceDependencies.syncs.push(sync);
    });
    mergedSourceDependencies.sources.push(source);
  });
  return mergedSourceDependencies;
};

enum Tab {
  MODELS = "Models",
  SYNCS = "Syncs",
}

const getTableTabs = (mergedDependencies: MergedSourceDependencies) => [
  {
    render: () => (
      <Row>
        {Tab.MODELS}
        <Badge sx={{ ml: 2 }} variant="baseCircle">
          {mergedDependencies.models.length}
        </Badge>
      </Row>
    ),
    value: Tab.MODELS,
  },
  {
    render: () => (
      <Row>
        {Tab.SYNCS}
        <Badge sx={{ ml: 2 }} variant="baseCircle">
          {mergedDependencies.syncs.length}
        </Badge>
      </Row>
    ),
    value: Tab.SYNCS,
  },
];

export const BulkDeleteSourcesModal: FC<Readonly<Props>> = ({
  isOpen,
  loading,
  sources,
  onCancel,
  onDelete,
  workspaceName,
}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [tab, setTab] = useState<Tab>(Tab.MODELS);

  const { data: sourceDependenciesData, isLoading: loadingDependencies } = useListAllSourceDependenciesQuery(
    { ids: sources },
    { enabled: isOpen },
  );

  const { data: sourceDefinitions } = useSourceDefinitionsQuery({}, { enabled: isOpen });
  const [sourceIconLookup, setSourceIconLookup] = useState<{ [key: string]: { icon: string } }>({});

  useEffect(() => {
    if (sourceDefinitions) {
      const sourceIconLookup: { [key: string]: { icon: string } } = {};
      sourceDefinitions.getSourceDefinitions.forEach((sourceDefinition) => {
        sourceIconLookup[sourceDefinition.type] = { icon: sourceDefinition.icon };
      });
      setSourceIconLookup(sourceIconLookup);
    }
  }, [sourceDefinitions]);

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery({}, { enabled: isOpen });

  const [destinationIconLookup, setDestinationIconsLookup] = useState<Record<string, { icon: string }>>({});

  useEffect(() => {
    if (destinationDefinitions?.getDestinationDefinitions) {
      destinationDefinitions.getDestinationDefinitions.forEach((dest) => {
        setDestinationIconsLookup((defs) => ({ ...defs, [dest.type]: dest }));
      });
    }
  }, [destinationDefinitions]);

  const [mergedSourceDependencies, setMergedSourceDependencies] = useState<MergedSourceDependencies>({
    sources: [],
    models: [],
    syncs: [],
  });
  useEffect(() => {
    if (sourceDependenciesData?.listAllSourceDependencies) {
      setMergedSourceDependencies(mergeSourceDependencies(sourceDependenciesData.listAllSourceDependencies));
    }
  }, [sourceDependenciesData]);

  const modelsColumns: TableColumn[] = [
    {
      name: "NAME",
      cell: ({ name: modelName, modelSourceType, id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Row key={id}>
            {sourceIconLookup[modelSourceType]?.icon ? (
              <Image
                alt={modelSourceType}
                src={sourceIconLookup[modelSourceType]?.icon}
                sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
              />
            ) : (
              <ChartIcon sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }} />
            )}
            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {modelName}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "TYPE",
      cell: ({ modelType, id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Row key={id}>
            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {modelType.toUpperCase()}
            </Text>
          </Row>
        );
      },
    },
    {
      cell: ({ id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Row key={id}>
            <Link newTab={true} to={`/models/${id}`}>
              <ExternalLinkIcon color={"base.5"} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const syncsColumns: TableColumn[] = [
    {
      name: "SOURCE MODEL",
      cell: ({ modelName }: NonNullable<MergedSourceDependencies["syncs"][0]>) => {
        return (
          <Row>
            <ChartIcon sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }} />
            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {modelName}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "DESTINATION",
      cell: ({ destinationName, destinationType }: NonNullable<MergedSourceDependencies["syncs"][0]>) => {
        return (
          <Row>
            <Image
              alt={destinationName ?? "Model Icon"}
              src={destinationIconLookup[destinationType]?.icon ?? ""}
              sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
            />
            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {destinationName}
            </Text>
          </Row>
        );
      },
    },
    {
      cell: ({ id }: NonNullable<MergedSourceDependencies["models"][0]>) => {
        return (
          <Row key={id}>
            <Link newTab={true} to={`/syncs/${id}`}>
              <ExternalLinkIcon color={"base.5"} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const sourceDependencyContent = (mergedDependencies: MergedSourceDependencies, loadingDependencies: boolean) => {
    return (
      <>
        <Text sx={{ color: "base.6", fontSize: 1, mb: 3 }}>
          We found some resources using {pluralize("this", mergedDependencies.sources.length, false)}{" "}
          {pluralize("source", mergedDependencies.sources.length, false)}. In order to delete the source we will need to also
          permanently delete the following dependent resources:
        </Text>

        <Tabs
          setTab={(tab) => setTab(tab as Tab)}
          sx={{ backgroundColor: "base.1" }}
          tab={tab}
          tabs={getTableTabs(mergedDependencies)}
        />
        {tab === Tab.MODELS && (
          <Table
            columns={modelsColumns}
            data={mergedDependencies.models}
            loading={loadingDependencies}
            showHeaders={false}
            sx={{ mt: 2, height: "100%" }}
            tableSx={TableStyles}
          />
        )}
        {tab === Tab.SYNCS && (
          <Table
            columns={syncsColumns}
            data={mergedDependencies.syncs}
            loading={loadingDependencies}
            sx={{ mt: 2 }}
            tableSx={TableStyles}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      bodySx={{ pb: 5 }}
      footer={
        <>
          <Field label="Please type the name of your workspace to confirm deletion">
            <Input
              placeholder={workspaceName}
              value={deleteConfirmation}
              onChange={(input: string) => setDeleteConfirmation(input)}
            />
          </Field>
          <Row>
            <Button
              sx={{ mr: 2 }}
              variant="secondary"
              onClick={() => {
                onCancel();
                setDeleteConfirmation("");
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={deleteConfirmation !== workspaceName}
              loading={loading}
              variant="red"
              onClick={() => {
                onDelete();
                setDeleteConfirmation("");
                onCancel();
              }}
            >
              Delete
            </Button>
          </Row>
        </>
      }
      footerSx={{ alignItems: "end", justifyContent: "space-between" }}
      header={
        <Row>
          <InfoIcon sx={{ color: "red", mr: 3 }} />
          <Heading>
            Delete {pluralize("this", sources.length, false)} {pluralize("source", sources.length, false)}?
          </Heading>
        </Row>
      }
      isOpen={isOpen}
      sx={{ borderRadius: 0, width: "600px" }}
      onClose={onCancel}
    >
      <Box>
        {mergedSourceDependencies.models.length > 0 ? (
          sourceDependencyContent(mergedSourceDependencies, loadingDependencies)
        ) : (
          <Text>These sources will be permanently deleted. Please confirm this action below</Text>
        )}
      </Box>
    </Modal>
  );
};

const TableStyles = {
  backgroundColor: "base.1",
  overflow: "auto",
  height: "400px",
};
