import { FC } from "react";

import { Flex, Grid, Image, Text } from "theme-ui";

import { DestinationDefinitionsQuery, OrganizationDestinationTypesQuery } from "src/graphql";
import { freeDestinationsTypes, premiumDestinationsTypes } from "src/hooks/use-entitlement";
import { Badge } from "src/ui/badge";
import { Box } from "src/ui/box";
import { Label } from "src/ui/label";
import { Spinner } from "src/ui/loading";

const unknownDestination = {
  type: "unknown",
  name: "Unknown",
  icon: "",
};
interface DestinationDefinition {
  type: string;
  name: string;
  icon: string;
}

interface Props {
  destinationTypes: OrganizationDestinationTypesQuery | undefined;
  isLoadingDestinationTypes: boolean;
  definitions: DestinationDefinitionsQuery | undefined;
  isLoadingDefinitions: boolean;
}

export const DestinationTiles: FC<Props> = ({
  destinationTypes,
  isLoadingDestinationTypes,
  definitions,
  isLoadingDefinitions,
}) => {
  return (
    <Box>
      {isLoadingDestinationTypes || isLoadingDefinitions ? (
        <Flex sx={{ flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "space-between", p: 6 }}>
          <Spinner size={64} />
        </Flex>
      ) : (
        <Grid columns={["1fr", "1fr", "repeat(2,1fr)", "repeat(3, 1fr)", "repeat(3, 264px)"]} gap={4} sx={{ mr: 12, flex: 1 }}>
          {destinationTypes?.getOrganizationDestinationTypes.map((destinationType) => {
            if (!destinationType) {
              return null;
            }

            const definition: DestinationDefinition =
              definitions?.getDestinationDefinitions.find((dest) => dest.type === destinationType.type) || unknownDestination; // If defintion can not be found in the loaded definitions

            return (
              <Flex
                key={definition.type}
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 5,
                  bg: "white",
                  border: "small",
                  position: "relative",
                }}
              >
                {definition.icon && <Image alt={definition.name} src={definition.icon} sx={{ width: 36 }} />}

                <Text sx={{ fontWeight: "bold", ml: 4 }}>{definition.name}</Text>

                {(freeDestinationsTypes.includes(destinationType.type) ||
                  premiumDestinationsTypes.includes(destinationType.type)) && (
                  <Box style={{ position: "absolute", right: -10, top: -10 }}>
                    <Badge sx={{ bg: "primary", color: "white" }}>
                      {freeDestinationsTypes.includes(destinationType.type) ? "Free" : "Premium"}
                    </Badge>
                  </Box>
                )}

                <Flex sx={{ flex: "1 1 24px", justifyContent: "end" }}>
                  <Badge
                    tooltip={`There are ${destinationType.count} destination(s) of this type in the organization.`}
                    variant="baseCircle"
                  >
                    {destinationType.count}
                  </Badge>
                </Flex>
              </Flex>
            );
          })}
          {destinationTypes?.getOrganizationDestinationTypes?.length === 0 && (
            <Label sx={{ marginBottom: 4 }}>No destinations found</Label>
          )}
        </Grid>
      )}
    </Box>
  );
};
