import { ReactNode, useEffect, FC } from "react";

import { get, isEqual, uniqueId } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { Flex, Grid } from "theme-ui";

import { Box, Row } from "src/ui/box";
import { Checkbox } from "src/ui/checkbox";
import { Editor } from "src/ui/editor";
import { Field, FieldError } from "src/ui/field";
import { FileUploader } from "src/ui/file";
import { Input, TextArea } from "src/ui/input";
import { Label } from "src/ui/label";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { CreatableSelect, Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";
import { Toggle } from "src/ui/toggle";
import { fetcher } from "src/utils/fetcher";

import {
  ComponentType,
  FormkitComponent,
  FormkitNode,
  getUnaryBooleanValue,
  LayoutType,
  NodeType,
  ReferenceType,
} from "../../../formkit";
import { KeyValueMapping } from "../components/destinations/key-value-mapping";
import { Button } from "../ui/button";
import { Code } from "../ui/code";
import { Link } from "../ui/link";
import { Message } from "../ui/message";
import { AssociationMappings } from "./components/association-mappings";
import { Collapsible } from "./components/collapsible";
import { Form } from "./components/form";
import { FormkitContextType, useFormkitContext } from "./components/formkit-context";
import { Mapping } from "./components/mapping";
import { Mappings } from "./components/mappings";
import { Modifier } from "./components/modifier";

type FormComponentProps = any;

export const graphQLFetch = async ({ query, variables }) => {
  if (query) {
    const fetch = fetcher(query, variables);

    const response = await fetch();

    return response ? Object.values(response as Record<string, unknown>)?.[0] : undefined;
  }
};

const FormComponentMap: Record<ComponentType, FC<FormComponentProps>> = {
  [ComponentType.Collapsible]: ({ label, children, name }) => {
    return (
      <Controller
        name={name}
        render={({ field }) => (
          <Collapsible label={label} value={field.value} onChange={field.onChange}>
            <Form compact disableBorder>
              {children}
            </Form>
          </Collapsible>
        )}
      />
    );
  },
  [ComponentType.Checkbox]: ({ name, label, error }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => <Checkbox label={label} value={field.value} onChange={field.onChange} />}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Select]: ({ creatable, createLabelPrefix = "object", error, multi, name, options, placeholder }) => {
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: !Array.isArray(options),
    });

    const selectFieldProps = {
      isClearable: true,
      isError: Boolean(error),
      isLoading: isFetching,
      isMulti: multi,
      options: Array.isArray(options) ? options : data,
      placeholder,
      reload: Array.isArray(options) ? undefined : refetch,
    };

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return creatable ? (
              <CreatableSelect
                {...selectFieldProps}
                formatCreateLabel={(value) => `Create ${createLabelPrefix} "${value}"...`}
                value={field.value}
                onChange={(option) => field.onChange(option?.value || null)}
              />
            ) : (
              <Select
                {...selectFieldProps}
                value={field.value}
                onChange={
                  multi
                    ? (options) => field.onChange(options?.map((v) => v.value) || [])
                    : (option) => field.onChange(option?.value || null)
                }
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Input]: ({ name, error, placeholder, type, disable, style, readOnly, value }) => {
    const { register, setValue } = useFormContext();
    const { data: queriedValue, error: queryError } = useQuery<any>(JSON.stringify({ name, variables: value?.variables }), {
      queryFn: () => graphQLFetch({ query: value?.query, variables: value?.variables }),
      enabled: value && typeof value !== "string",
      keepPreviousData: true,
    });
    const hardcodedValue = value ? (typeof value === "string" ? value : queriedValue) : undefined;

    useEffect(() => {
      if (hardcodedValue != null) {
        setValue(name, hardcodedValue);
      }
    }, [hardcodedValue]);

    return (
      <>
        <Input
          {...register(name, {
            setValueAs: (v) => {
              if (type === "number") {
                return v ? Number(v) : undefined;
              } else {
                return v || undefined;
              }
            },
            value: hardcodedValue,
          })}
          disabled={getUnaryBooleanValue(disable)}
          placeholder={placeholder}
          readOnly={readOnly}
          sx={{ borderColor: error ? "red !important" : undefined, fontFamily: style === "editor" ? "monospace" : undefined }}
          type={type}
          value={hardcodedValue}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Secret]: ({ name, error, isSetup, optional, multiline }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <SensitiveField
              hideSecret={isSetup ? false : true}
              multiline={multiline}
              optional={optional}
              sx={{ borderColor: error ? "red !important" : undefined }}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.KeyValueMapping]: ({ name, error }) => {
    return (
      <>
        <Controller name={name} render={({ field }) => <KeyValueMapping mapping={field.value} setMapping={field.onChange} />} />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.File]: ({ name, error, acceptedFileTypes, transformation }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <FileUploader
              acceptedFileTypes={acceptedFileTypes}
              transformation={transformation}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Textarea]: ({ name, error, placeholder, style }) => {
    const { register } = useFormContext();
    return (
      <>
        <TextArea
          {...register(name, {
            setValueAs: (v) => {
              return typeof v === "string" ? v : "";
            },
          })}
          error={Boolean(error)}
          placeholder={placeholder}
          rows={18}
          sx={{ fontFamily: style === "editor" ? "monospace" : undefined }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Editor]: ({ name, error, placeholder }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <Editor
              code={field.value || ""}
              language="liquid"
              placeholder={placeholder}
              sx={{ resize: "vertical", height: 170, width: "100%" }}
              theme="sqlserver"
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Code]: ({ name, title, content, error }) => {
    const { data = [], error: queryError } = useQuery<any>(JSON.stringify({ name, variables: content?.variables }), {
      queryFn: () => graphQLFetch({ query: content?.query, variables: content?.variables }),
      enabled: !Array.isArray(content),
      keepPreviousData: true,
    });

    return (
      <>
        <Controller
          name={name}
          render={() => (
            <Code title={title}>
              {(Array.isArray(content) ? content : data).map((content, index) => (
                <div key={index}>{content}</div>
              ))}
            </Code>
          )}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Message]: ({ message, error }) => {
    return (
      <>
        <Message sx={{ minWidth: "100%" }}>{message} </Message>
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Mapping]: ({ name, error, options, creatable, creatableTypes }) => {
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: !Array.isArray(options),
    });

    return (
      <>
        <Mapping
          creatable={creatable}
          creatableTypes={creatableTypes}
          error={error}
          loading={isFetching}
          name={name}
          options={Array.isArray(options) ? options : data}
          reload={Array.isArray(options) ? undefined : refetch}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Mappings]: ({
    name,
    allEnabled,
    autoSyncColumnsDefault,
    allEnabledKey,
    allEnabledLabel,
    creatable,
    creatableTypes,
    options,
    error,
    advanced,
    required,
    excludeMappings,
    associationOptions,
    templates,
  }) => {
    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;

    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: asyncOptions,
    });

    return (
      <>
        <Mappings
          advanced={advanced}
          allEnabled={allEnabled}
          allEnabledKey={allEnabledKey}
          allEnabledLabel={allEnabledLabel}
          associationOptions={associationOptions}
          autoSyncColumnsDefault={autoSyncColumnsDefault}
          creatable={creatable}
          creatableTypes={creatableTypes}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={asyncOptions ? data : options}
          reload={asyncOptions ? refetch : undefined}
          required={required}
          templates={templates}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.AssociationMappings]: ({ name, options, error, excludeMappings, ascOptions }) => {
    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;

    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: asyncOptions,
    });

    return (
      <>
        <AssociationMappings
          ascOptions={ascOptions}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={asyncOptions ? data : options}
          reload={asyncOptions ? refetch : undefined}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.RadioGroup]: ({ name, options, error }) => {
    const {
      data,
      error: queryError,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: !Array.isArray(options),
    });

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <RadioGroup
                loading={isFetching}
                options={Array.isArray(options) ? options : data}
                value={field.value === null ? undefined : field.value}
                onChange={(value) => (value === undefined ? field.onChange(null) : field.onChange(value))}
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Button]: ({ label, error, mode, url }) => {
    const { data: queriedValue = "", error: queryError } = useQuery<any>(JSON.stringify({ name, variables: url?.variables }), {
      queryFn: () => graphQLFetch({ query: url?.query, variables: url?.variables }),
      enabled: url && typeof url !== "string",
      keepPreviousData: true,
    });
    const generatedUrl = url ? (typeof url === "string" ? url : queriedValue) : undefined;

    return (
      <>
        {mode === "link" && (
          <Link to={generatedUrl}>
            <Button>{label}</Button>
          </Link>
        )}
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Column]: ({ name, error }) => {
    const { columns, reloadModel, loadingModel } = useFormkitContext();
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <Select
              isClearable={true}
              isError={Boolean(error)}
              isLoading={loadingModel}
              options={columns}
              placeholder={"Select a column..."}
              reload={reloadModel}
              value={field.value?.from}
              width={340}
              onChange={(option) => field.onChange(option?.value ? { from: option?.value } : null)}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.ColumnOrConstant]: ({ name, options, constantComponentType, error }) => {
    const { columns, reloadModel, loadingModel } = useFormkitContext();
    const { register } = useFormContext();

    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;

    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      queryFn: () => graphQLFetch({ query: options?.query, variables: options?.variables }),
      enabled: asyncOptions,
    });

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            const isColumn = typeof field.value === "object" && field.value !== null && field.value !== undefined;
            return (
              <Row sx={{ display: "flex", justifyContent: "space-between" }}>
                {isColumn ? (
                  <Select
                    isClearable={true}
                    isError={Boolean(error)}
                    isLoading={loadingModel}
                    options={columns}
                    placeholder={"Select a column..."}
                    reload={reloadModel}
                    value={field.value?.from}
                    width={340}
                    onChange={(option) => field.onChange(option?.value ? { from: option?.value } : { from: undefined })}
                  />
                ) : options && (constantComponentType === ComponentType.Select || constantComponentType === undefined) ? (
                  <Select
                    isClearable={true}
                    isError={Boolean(error)}
                    isLoading={isFetching}
                    options={asyncOptions ? data : options}
                    placeholder={"Select an option..."}
                    reload={asyncOptions ? refetch : undefined}
                    value={field.value}
                    width={340}
                    onChange={(option) => field.onChange(option?.value || undefined)}
                  />
                ) : options && constantComponentType === ComponentType.RadioGroup ? (
                  <RadioGroup
                    loading={isFetching}
                    options={options}
                    sx={{ width: "70%" }}
                    value={field.value === null ? undefined : field.value}
                    onChange={(value) => (value === undefined ? field.onChange(null) : field.onChange(value))}
                  />
                ) : (
                  <Input
                    {...register(name)}
                    placeholder={"Enter a value..."}
                    sx={{ borderColor: error ? "red !important" : undefined, maxWidth: "340px" }}
                  />
                )}
                <Toggle
                  label={"Use column"}
                  sx={{ alignItems: "flex-start", mr: 4 }}
                  value={isColumn}
                  onChange={(value) => {
                    if (constantComponentType === ComponentType.RadioGroup) {
                      value ? field.onChange({ from: undefined }) : field.onChange(options[0].value);
                    } else if (options) {
                      // If options exist and constantComponentType is not equal to RadioGroup, the component is a drop down.
                      value ? field.onChange({ from: undefined }) : field.onChange(undefined);
                    } else {
                      // Component is an input field
                      value ? field.onChange({ from: undefined }) : field.onChange("");
                    }
                  }}
                />
              </Row>
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
};

const getComponent = (node: FormkitComponent) => FormComponentMap[node.component];

export const processReferences = (value: any, context: FormkitContextType, watch): unknown => {
  if (Array.isArray(value)) {
    return value;
  } else if (typeof value === "object" && value !== undefined && value !== null) {
    if (value.type) {
      if (value.type === ReferenceType.GraphQL) {
        const variables = processReferences(value.variables, context, watch);
        return { query: value.document, variables };
      } else if (value.type === ReferenceType.Context) {
        const contextValue = get(context, value.key);
        return contextValue;
      } else if (value.type === ReferenceType.State) {
        return watch(value.key);
      }
    }

    let newObject = {};
    for (const [k, v] of Object.entries(value)) {
      const processedValue = processReferences(v, context, watch);
      newObject = { ...newObject, [k]: processedValue };
    }

    return newObject;
  } else {
    return value;
  }
};

const Node: FC<{ node: FormkitComponent; children?: ReactNode }> = ({ node, children }) => {
  const context = useFormkitContext();
  const {
    watch,
    resetField,
    formState: { errors },
  } = useFormContext();

  const props = processReferences(node.props, context, watch) as Record<string, unknown>;

  const Component = getComponent(node);

  const rawError = get(errors, node.key)?.message;
  let error = typeof rawError === "string" ? rawError.replace(node.key, "This") : rawError;
  // Doing this because columns `{ from: string }` gets validate from inside out.
  // For example: a returned validation errors is `errors: { "eventId.from": "eventId.from is required."}`
  if (
    !error &&
    node.type === NodeType.Component &&
    [ComponentType.Column, ComponentType.ColumnOrConstant].includes(node.component)
  ) {
    const key = `${node.key}.from`;

    const rawError = get(errors, key)?.message;
    error = typeof rawError === "string" ? rawError.replace(node.key, "This") : rawError;
  }

  const value = watch(node.key);

  useEffect(() => {
    if (node.props?.default !== undefined && !value) {
      resetField(node.key, { defaultValue: node.props.default });
    }
  }, []);

  const isChangedInDraft =
    !children &&
    context?.draftChanges?.find(({ key }) => {
      const nodePath = node.key.split(".");
      const keyPath = key.split(".");
      return isEqual(nodePath, keyPath.slice(0, nodePath.length));
    })?.op;

  return (
    <Box
      sx={
        isChangedInDraft
          ? {
              position: "relative",
              "::after": {
                content: '""',
                top: 0,
                left: -5,
                display: "block",
                width: "4px",
                height: "100%",
                position: "absolute",
                borderRadius: "2px",
                backgroundColor: isChangedInDraft === "add" ? "green" : "yellow",
              },
            }
          : {}
      }
    >
      <Component {...props} error={error} isSetup={context.isSetup} name={node.key}>
        {children}
      </Component>
    </Box>
  );
};

export const processFormNode = (node: FormkitNode, depth = 0) => {
  if (node.type === NodeType.Layout) {
    if (node.layout === LayoutType.Section) {
      if (node.parent) {
        return (
          <Section key={node.heading}>
            <Flex sx={{ backgroundColor: "base.1", borderTop: "small", px: 6, mx: -6, mt: -6, mb: 6, pt: 6 }}>
              <Label description={node.subheading} size={"large"} sx={{ color: "base.6" }}>
                {node.heading}
              </Label>
            </Flex>
            <Form compact>{node.children.map((node) => processFormNode(node, depth + 1))}</Form>
          </Section>
        );
      }
      return (
        <Section key={node.heading} sx={{ pb: depth > 0 ? 3 : undefined }}>
          <Field
            description={node.subheading}
            label={node.heading ?? ""}
            optional={node.optional}
            size={node.size == "small" ? "small" : "large"}
          >
            <Grid gap={3}>{node.children.map((node) => processFormNode(node, depth + 1))}</Grid>
          </Field>
        </Section>
      );
    }

    if (node.layout === LayoutType.Form) {
      return node.children.map((node) => processFormNode(node, depth + 1));
    }
  }

  if (node.type === NodeType.Component) {
    return (
      <Node key={uniqueId()} node={node}>
        {node.children?.map((node) => processFormNode(node, depth + 1))}
      </Node>
    );
  }

  if (node.type === NodeType.Modifier) {
    return (
      <Modifier key={uniqueId()} condition={node.condition} node={node} type={node.modifier}>
        {node.children.map((node) => processFormNode(node, depth + 1))}
      </Modifier>
    );
  }

  return null;
};

export const getNestedKeys = (node: FormkitNode) => {
  if (node.type === NodeType.Component) {
    return node.key;
  } else {
    return node.children.map(getNestedKeys);
  }
};
