import { FC } from "react";

import pluralize from "pluralize";
import { Text } from "theme-ui";

import {
  EventCondition,
  eventOperatorOptions,
  initialFunnelCondition,
  initialPropertyCondition,
  IntervalUnit,
  TimestampOperator,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { FilterIcon } from "src/ui/icons/filter";
import { Input } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import {
  ConditionField,
  FilterProps,
  HStack,
  OperatorLabel,
  PlusButton,
  RemoveButton,
  removeSubcondition,
  updateSubconditions,
} from "./condition";
import { Filter } from "./filter";
import { FunnelFilter } from "./funnel-filter";
import { WindowFilter } from "./window-filter";

export const EventFilter: FC<Readonly<FilterProps<EventCondition>>> = (props) => {
  const { events, condition, onChange, onRemove, isEventTrait } = props;

  const eventOptions = events?.map(({ id: relationshipId, name, to_model: { id: eventModelId } }) => ({
    label: name,
    value: eventModelId,
    relationshipId,
  }));

  const operatorLabel = eventOperatorOptions.find(({ value }) => value === condition.operator)?.label;

  return (
    <>
      <HStack gap={2}>
        <AttributeSelect
          options={eventOptions}
          placeholder="Select an event"
          value={condition.eventModelId}
          onChange={(eventModelId, { relationshipId }) => {
            onChange({
              relationshipId,
              eventModelId,
            });
          }}
        />
        {!isEventTrait && (
          <>
            <Filter
              content={
                <>
                  <NewSelect
                    options={eventOperatorOptions}
                    placeholder={"Filter on"}
                    value={condition.operator}
                    onChange={(operator) => {
                      onChange({
                        operator,
                      });
                    }}
                  />
                  <Input
                    size="small"
                    sx={{ width: "100px" }}
                    type="number"
                    value={String(condition.value)}
                    onChange={(value) => {
                      onChange({
                        value: value ? Number(value) : null,
                      });
                    }}
                  />
                </>
              }
            >
              <OperatorLabel>{operatorLabel}</OperatorLabel>
              <Text>{`${condition.value} ${pluralize("time", false)}`}</Text>
            </Filter>
            <RemoveButton onRemove={onRemove} />
          </>
        )}
      </HStack>

      {condition.window && <WindowFilter condition={condition} onChange={onChange} />}
      {condition.subconditions?.map((subcondition, index) => (
        <Row key={index} sx={{ alignItems: "center", pl: 12, width: "100%" }}>
          <Text sx={{ flexShrink: 0, color: "base.4", width: "3.5rem" }}>{index === 0 ? "WHERE" : "AND"}</Text>
          <ConditionField
            {...props}
            columns={events?.find(({ id }) => id === condition.relationshipId)?.to_model?.filterable_audience_columns}
            condition={subcondition}
            traits={[]}
            onChange={updateSubconditions(onChange, condition.subconditions, index)}
            onRemove={removeSubcondition(onChange, condition.subconditions, index)}
          />
        </Row>
      ))}
      <HStack gap={4} sx={{ pl: 12 }}>
        <PlusButton
          disabled={!condition.eventModelId}
          onClick={() => {
            onChange({ subconditions: [...(condition.subconditions ?? []), initialPropertyCondition] });
          }}
        >
          property
        </PlusButton>
        {!condition.window && (
          <PlusButton
            disabled={!condition.eventModelId}
            onClick={() => {
              onChange({
                window: {
                  operator: TimestampOperator.Within,
                  value: { interval: IntervalUnit.Day, quantity: 7 },
                },
              });
            }}
          >
            time window
          </PlusButton>
        )}
      </HStack>
      {condition.funnelCondition ? (
        <FunnelFilter
          {...props}
          condition={condition.funnelCondition}
          eventCondition={condition}
          onChange={(updates) => {
            onChange({
              funnelCondition: {
                ...condition.funnelCondition!,
                ...updates,
              },
            });
          }}
          onRemove={() => onChange({ funnelCondition: undefined })}
        />
      ) : (
        <HStack gap={4}>
          <Button
            disabled={!condition.eventModelId}
            iconBefore={<FilterIcon size={14} />}
            label="and then did perform"
            size="small"
            variant="gray"
            onClick={() => {
              onChange({
                funnelCondition: { ...initialFunnelCondition, didPerform: true },
              });
            }}
          />
          <Button
            disabled={!condition.eventModelId}
            iconBefore={<FilterIcon size={14} />}
            label="and then did not perform"
            size="small"
            variant="gray"
            onClick={() => {
              onChange({
                funnelCondition: { ...initialFunnelCondition, didPerform: false },
              });
            }}
          />
        </HStack>
      )}
    </>
  );
};
