import { FC, useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications2";
import { Flex, Grid } from "theme-ui";

import { useCreateGitSyncConfigMutation, useGitSyncConfigQuery, useUpdateGitSyncConfigMutation } from "src/graphql";
import { Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Toggle } from "src/ui/toggle";

export const DbtGitSync: FC = () => {
  const { data } = useGitSyncConfigQuery();
  const config = data?.git_sync_config?.[0];

  const [username, setUsername] = useState(config?.username || "");
  const [password, setPassword] = useState(config?.password || "");
  const [repository, setRepository] = useState(config?.repository || "");
  const [schema, setSchema] = useState<string | null>(config?.schema || "");
  const [bidi, setBidi] = useState(config?.bidi || false);
  const [advancedBitbucket, setAdvancedBitbucket] = useState(config?.bidi_metadata?.git_api_base_url?.length || false);
  const [bidiMetadata, setBidiMetadata] = useState(
    config?.bidi_metadata || { branch: "master", type: "commit", git_service: "github" },
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (config) {
      setUsername(config.username);
      setPassword(config.password);
      setRepository(config.repository);
      setSchema(config.schema);
      setBidi(config.bidi);
      setBidiMetadata(config.bidi_metadata || { branch: "master", type: "commit", git_service: "github" });
    }
  }, [config]);

  const { addToast } = useToasts();

  const { mutateAsync: createGitConfigSync } = useCreateGitSyncConfigMutation();
  const { mutateAsync: updateGitConfigSync } = useUpdateGitSyncConfigMutation();

  const save = async () => {
    try {
      setLoading(true);

      if (!config) {
        await createGitConfigSync({
          config: {
            username,
            password,
            repository,
            schema,
            bidi,
            bidi_metadata: bidiMetadata,
          },
        });
      } else {
        await updateGitConfigSync({
          id: config.id,
          config: {
            username,
            password,
            repository,
            schema,
            bidi,
            bidi_metadata: bidiMetadata,
          },
        });
      }
      addToast("Git Sync config saved!", {
        appearance: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Section
      divider
      footer={
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button label={"Save"} loading={loading} onClick={save} />
        </Flex>
      }
      title="Git Credentials"
    >
      <Container center={false} size="small">
        <Grid gap={8}>
          <Field label="Username">
            <Input value={username} onChange={setUsername} />
          </Field>

          <Field label="Token">
            <Input type="password" value={password} onChange={setPassword} />
          </Field>

          <Field label="Repository">
            <Input value={repository} onChange={setRepository} />
          </Field>

          <Field label="Schema">
            <Input value={schema || ""} onChange={setSchema} />
          </Field>

          <Field inline={true} label="Bidirectional" labelSx={{ fontWeight: "semi", color: "black" }}>
            <Toggle value={bidi} onChange={setBidi} />
          </Field>

          {bidi && (
            <>
              <Field label="Branch">
                <Input value={bidiMetadata?.branch || ""} onChange={(branch) => setBidiMetadata({ ...bidiMetadata, branch })} />
              </Field>

              {bidiMetadata.git_service === "bitbucket" && (
                <Field inline={true} label="Advanced Bitbucket Settings">
                  <Toggle value={advancedBitbucket} onChange={setAdvancedBitbucket} />
                </Field>
              )}

              {advancedBitbucket && (
                <Field label="Bitbucket API Endpoint">
                  <Input
                    value={bidiMetadata?.git_api_base_url || ""}
                    onChange={(endpoint) =>
                      setBidiMetadata({ ...bidiMetadata, git_api_base_url: endpoint.length ? endpoint : undefined })
                    }
                  />
                </Field>
              )}
            </>
          )}
        </Grid>
      </Container>
    </Section>
  );
};
