import { useEffect, useState, FC } from "react";

import { Box, Text } from "theme-ui";

import { AudienceBreakdownResult } from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PlusIcon } from "src/ui/icons";
import { OverlaySpinner } from "src/ui/loading";
import { NewSelect } from "src/ui/new-select";

import { Indices } from "../../../../design/indices";
import { BreakdownGraph, colors, hoverColors } from "./breakdown-graph";

const options = [
  { label: "Bar chart", value: "bar" },
  { label: "Pie chart", value: "pie" },
  { label: "Table", value: "table" },
];

export type BreakdownColumn = { name: string; type: string; modelId: string };
export type ChartType = "pie" | "bar" | "table";
type Props = {
  breakdownData: AudienceBreakdownResult["columns"];
  chartType: ChartType;
  columns: BreakdownColumn[];
  graphWidth?: number;
  loading?: boolean;
  onChartTypeChange(chartType: ChartType): void;
  onClearBreakdowns(): void;
  onRemoveBreakdown(columnName: string): void;
  onSubmit(column: BreakdownColumn): void;
};

export const AudienceBreakdowns: FC<Props> = ({
  breakdownData,
  chartType,
  columns,
  graphWidth = 400,
  loading,
  onClearBreakdowns,
  onChartTypeChange,
  onRemoveBreakdown,
  onSubmit,
}) => {
  const [showCompareList, setShowCompareList] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState<BreakdownColumn | undefined>(undefined);
  const [animate, setAnimate] = useState(true);
  const [activeGraph, setActiveGraph] = useState<null | number>(null);

  const resetForm = () => {
    setShowCompareList(false);
    setSelectedColumn(undefined);
  };

  const clearForm = () => {
    resetForm();
    setShowCompareList(false);
    onClearBreakdowns();
  };

  const selectColumn = (columnName: string) => {
    const column = columns.find((column) => column.name === columnName);
    setSelectedColumn(column ?? undefined);
  };

  const submit = () => {
    if (selectedColumn) {
      onSubmit(selectedColumn);
      setSelectedColumn(undefined);
    }
  };

  useEffect(() => {
    resetForm();
  }, [breakdownData.length]);

  if (!showCompareList && breakdownData.length === 0) {
    return (
      <Column sx={{ flex: 1, minHeight: 0, justifyContent: "center", alignItems: "center", mx: 4, overflowY: "auto" }}>
        <Box sx={{ alignItems: "flex-end", flexShrink: 0, width: "141px", height: "150px", my: 10 }}>
          <Box sx={{ display: "inline-block", width: "25%", height: "100%", bg: colors[0] }} />
          <Box sx={{ display: "inline-block", width: "25%", height: "77%", bg: colors[1] }} />
          <Box sx={{ display: "inline-block", width: "25%", height: "50%", bg: colors[0] }} />
          <Box sx={{ display: "inline-block", width: "25%", height: "25%", bg: colors[1] }} />
        </Box>

        <Text sx={{ color: "base.5", textAlign: "center" }}>
          Visualize how this audience breaks down by a column property or trait
        </Text>

        <Row sx={{ justifyContent: "center", mt: 4, mb: 8 }}>
          <Button
            iconBefore={<PlusIcon color="base.3" size={20} />}
            sx={{
              bg: "secondaries.0",
            }}
            variant="secondary"
            onClick={() => setShowCompareList(true)}
          >
            Add Breakdown
          </Button>
        </Row>
      </Column>
    );
  }

  const changeChartType = (value: string) => {
    setAnimate(true);
    setActiveGraph(null);
    onChartTypeChange(value as ChartType);
  };

  const dropdownOptions = columns.map((column) => ({ label: column.name, value: column.name }));

  return (
    <Column sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>
      {breakdownData?.length > 0 && (
        <Row
          sx={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: Number(Indices.Content) + 2,
            alignItems: "center",
            px: 4,
            py: 4,
          }}
        >
          <Text
            sx={{
              textTransform: "uppercase",
              mr: 2,
              fontSize: "10px",
              color: "base.4",
              fontWeight: "bold",
              cursor: "auto",
            }}
          >
            Show as
          </Text>
          <NewSelect loading={loading} options={options} value={chartType} onChange={changeChartType} />
        </Row>
      )}
      <Box sx={{ position: "relative" }}>
        {breakdownData.length > 0 && (
          <>
            {loading && <OverlaySpinner sx={{ zIndex: Number(Indices.Content) + 1, height: "100%" }} />}
            <Row as="ul" sx={{ width: "100%", flexWrap: "wrap", mt: 2, p: 0 }}>
              {breakdownData.map(({ name, values }, index) => (
                <BreakdownGraph
                  key={`name-${index}`}
                  chartType={chartType}
                  color={colors[index % 2]}
                  data={values}
                  graphName={name}
                  graphWidth={graphWidth}
                  hoverColor={hoverColors[index % 2]}
                  isActive={index === activeGraph}
                  isAnimationActive={animate}
                  secondaryAxisLabel="Count"
                  onFilterChange={() => setAnimate(true)}
                  onMouseEnterCell={() => {
                    setAnimate(false);
                    setActiveGraph(index);
                  }}
                  onMouseLeaveCell={() => setActiveGraph(null)}
                  onRemoveGraph={onRemoveBreakdown}
                />
              ))}
            </Row>
          </>
        )}
      </Box>

      <Column sx={{ mx: 4, my: 4 }}>
        {showCompareList || loading ? (
          <>
            <NewSelect
              searchable
              loading={loading}
              options={dropdownOptions}
              placeholder="Select a column to breakdown"
              sx={{ mb: 4 }}
              value={selectedColumn?.name}
              onChange={selectColumn}
            />

            <Row>
              <Button disabled={!selectedColumn} loading={loading} sx={{ mr: 3 }} onClick={submit}>
                Continue
              </Button>
              <Button disabled={loading} variant="secondary" onClick={resetForm}>
                Cancel
              </Button>
            </Row>
          </>
        ) : (
          <Row sx={{ flexWrap: "wrap" }}>
            <Button
              iconBefore={<PlusIcon color="base.3" size={20} />}
              sx={{ mr: 3, mt: 2 }}
              variant="secondary"
              onClick={() => setShowCompareList(true)}
            >
              Breakdown
            </Button>
            <Button sx={{ mt: 2 }} variant="secondary" onClick={clearForm}>
              Clear
            </Button>
          </Row>
        )}
      </Column>
    </Column>
  );
};
